import styled from 'styled-components'

import { textStyles } from '../../theme'
import ProductDetail from '../../components/ProductDetail'
import SignatureCanvas from '../../components/SignatureCanvas'
import ActionButton from '../../components/ActionButton'
import Link from '../../components/Link'
import Select from '../../components/form/Select'

export const Content = styled.div`
  padding: 4rem 1rem;
  flex-grow: 1;
  display: flex;
`

export const Box = styled.div`
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  ${textStyles.titleH1};
  text-align: center;
`

export const Product = styled(ProductDetail)`
  width: 100%;
  margin-top: 50px;
`

export const DisclaimerTitle = styled.p`
  ${textStyles.titleH3};
  margin-top: 55px;
`

export const DisclaimerText = styled.div`
  ${textStyles.fieldLabel};
  margin-top: 25px;
  white-space: pre-wrap;
`

export const OtherLanguagesContainer = styled.div`
  margin-top: 25px;
`
export const OtherLanguages = styled(ActionButton)`
  ${textStyles.fieldLabel};
  width: fit-content;
`

export const LanguagesContainer = styled.div`
  ${textStyles.fieldLabel};
  margin-top: 20px;
  white-space: pre-wrap;
`

export const Dropdown = styled(Select)``

export const Language = styled(Link)`
  ${textStyles.fieldLabel};
  margin-right: 10px;
  text-decoration: underline;
`

export const CheckboxContainer = styled.label`
  ${textStyles.fieldLabel};
  margin-top: 5.5rem;
  position: relative;
  display: flex;
  align-items: center;
  ${textStyles.tiny}
  cursor: pointer;
`

export const InputCheckboxCheckmark = styled.span`
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
  background-color: white;
  border: 1px solid black;
  margin-right: 2.5rem;
`
export const InputCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  appearance: none;
  user-select: none;

  &:checked ~ ${InputCheckboxCheckmark} {
    background-color: black;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -65%) rotate(45deg);
    }
  }
`

export const Signature = styled(SignatureCanvas)`
  margin-top: 25px;
`

export const Buttons = styled.div`
  margin-top: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Back = styled.div`
  ${textStyles.text};
  padding: 10px 30px;
  cursor: pointer;
  margin-right: 25px;
  text-transform: uppercase;
`

export const ConfirmButton = styled(ActionButton)`
  width: 100%;
  max-width: 250px;
`

export const Pagination = styled.div`
  margin-top: 45px;
  ${textStyles.text};
  text-align: center;
`

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: auto;
`

export const SuccessTitle = styled.h1`
  ${textStyles.titleH3};
  text-align: center;
  text-align: center;
  white-space: pre-wrap;
`

export const SuccessLogout = styled(ActionButton)``
