import * as Yup from 'yup'
import iban from 'iban'

Yup.addMethod(Yup.string, 'siret', function (message?: string | ((p: any) => string)) {
  return this.test('siret', message ?? 'Siret error', function (value: any) {
    if (value?.length !== 14) {
      return false
    }
    let sum = 0
    let digit: number
    for (let i = 0; i < value.length; i++) {
      if (i % 2 === 0) {
        digit = parseInt(value.charAt(i), 10) * 2
        digit = digit > 9 ? (digit -= 9) : digit
      } else {
        digit = parseInt(value.charAt(i), 10)
      }
      sum += digit
    }
    return sum % 10 === 0
  })
})

Yup.addMethod(Yup.string, 'iban', function (message?: string | ((p: any) => string)) {
  return this.test('iban', message ?? 'IBAN error', function (value: any) {
    return iban.isValid(value)
  })
})

export default Yup
