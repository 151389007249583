import styled from 'styled-components'

export const Svg = styled.svg<{ color: string }>`
  color: ${(props) => props.color};
  fill: ${(props) => props.color};
`

export const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`
