import gql from 'graphql-tag'

import { AppGetZonesQuery } from '../../../types/api'

export default {
  query: gql`
    query AppGetZones {
      zones {
        id
        name
        countries {
          id
          name
        }
      }
    }
  `,
  transformer: (response: AppGetZonesQuery) => {
    return response.zones
  },
}
