import gql from 'graphql-tag'

import { AppGetDisclaimersQuery } from '../../../types/api'

export default {
  query: gql`
    query AppGetDisclaimers {
      disclaimers {
        id
        language
        isoCode
        title
        content
        url
        events {
          type
          name
          id
        }
      }
    }
  `,
  transformer: (response: AppGetDisclaimersQuery) => {
    return response.disclaimers
  },
}
