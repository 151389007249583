import React from 'react'
import { Formik } from 'formik'
import { FormikConfig } from 'formik/dist/types'

import * as SC from './styled'
import FormikContextObserver, { FormikContextObserverProps } from './FormikContextObserver'

export type FormikFormProps<Values = any> = FormikConfig<Values> & {
  className?: string
  onContextUpdate?: FormikContextObserverProps<Values>['onUpdate']
  children?: React.ReactNode
}

const FormikForm: React.FC<FormikFormProps> = ({
  className,
  onContextUpdate,
  children,
  ...props
}) => {
  return (
    <Formik validateOnChange={false} validateOnBlur={false} {...props}>
      {({ handleSubmit }) => (
        <>
          {onContextUpdate && <FormikContextObserver onUpdate={onContextUpdate} />}
          <SC.Form className={className} noValidate onSubmit={handleSubmit}>
            {children}
          </SC.Form>
        </>
      )}
    </Formik>
  )
}

export default FormikForm
