const colors = {
  pureWhite: '#FFF',
  pureBlack: '#000',
  lightGrey: '#F5F5F5',
  midGrey: '#808080',
}

const palette = {
  colors,
  error: {
    main: '#FF0000',
  },
}

export default palette
