import { css } from 'styled-components'

import { defineFont, Font, FontStyles, FontWeights } from '../utils/FontUtils'

enum FontKeys {
  PoppinsLight = 'PoppinsLight',
  PoppinsRegular = 'PoppinsRegular',
  PoppinsMedium = 'PoppinsMedium',
  PoppinsSemiBold = 'PoppinsSemiBold',
  PoppinsItalic = 'PoppinsItalic',
  PoppinsBold = 'PoppinsBold',
  LatoRegular = 'LatoRegular',
  UniversRoman = 'UniversRoman',
  UniversCondensed = 'UniversCondensed',
  UniversBoldCond = 'UniversBoldCond',
}

export const declarations: { [key in FontKeys]: Font } = {
  PoppinsRegular: {
    basename: 'Poppins-Regular',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsLight: {
    basename: 'Poppins-Light',
    fontFamily: 'Poppins',
    fontWeight: 300,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsMedium: {
    basename: 'Poppins-Medium',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsSemiBold: {
    basename: 'Poppins-SemiBold',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsItalic: {
    basename: 'Poppins-Italic',
    fontFamily: 'Poppins Italic',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.italic,
    fallback: 'sans-serif',
  },
  PoppinsBold: {
    basename: 'Poppins-Bold',
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  LatoRegular: {
    basename: 'Lato-Regular',
    fontFamily: 'Lato',
    fontWeight: FontWeights.regular,
    fontStyle: FontStyles.normal,
    fallback: 'sans-serif',
  },
  UniversRoman: {
    basename: 'UniversLTCYR-55Roman',
    fontFamily: 'UniversLTCYR-55Roman',
    fontWeight: FontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversCondensed: {
    basename: 'UniversLTCYR-57Condensed',
    fontFamily: 'UniversLTCYR-57Condensed',
    fontWeight: FontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversBoldCond: {
    basename: 'UniversLTCYR-67BoldCond',
    fontFamily: 'UniversLTCYR-67BoldCond',
    fontWeight: FontWeights.regular,
    fallback: 'sans-serif',
  },
}

export const fontsFaces: any[] = Object.values(declarations)?.map(
  (declaration) => css`
    @font-face {
      font-family: ${declaration.fontFamily};
      font-style: normal;
      font-display: swap;
      font-weight: ${declaration.fontWeight};
      src: url('/fonts/${declaration.basename}/${declaration.basename}.woff2') format('woff2');
    }
  `
)

const fonts: { [key in FontKeys]: string } = {
  PoppinsRegular: defineFont(declarations.PoppinsRegular),
  PoppinsLight: defineFont(declarations.PoppinsLight),
  PoppinsMedium: defineFont(declarations.PoppinsMedium),
  PoppinsSemiBold: defineFont(declarations.PoppinsSemiBold),
  PoppinsItalic: defineFont(declarations.PoppinsItalic),
  PoppinsBold: defineFont(declarations.PoppinsBold),
  LatoRegular: defineFont(declarations.LatoRegular),
  UniversRoman: defineFont(declarations.UniversRoman),
  UniversBoldCond: defineFont(declarations.UniversBoldCond),
  UniversCondensed: defineFont(declarations.UniversCondensed),
}

export default fonts
