import fonts from './fonts'
import breakpoints from './breakpoints'
import palette from './palette'
import typography from './typography'

const theme = {
  palette,
  fonts,
  breakpoints,
  typography,
}

export { default as textStyles } from './textStyles'

export default theme
