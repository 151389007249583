export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sP`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any
  /** A datetime string in ISO 8601 format in UTC with nanoseconds `YYYY-MM-DDTHH:mm:ss.SSSSSSZ`, e.g. `2020-04-20T16:20:04.000000Z`. */
  DateTimeUtc: any
}

export type Bag = Model & {
  __typename?: 'Bag'
  id: Scalars['ID']
  sku: Scalars['String']
  name: Scalars['String']
  color: Scalars['String']
  order: Scalars['Int']
  packshot?: Maybe<Scalars['String']>
  line: Line
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type City = {
  __typename?: 'City'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  country?: Maybe<Country>
  zone?: Maybe<Zone>
}

export type Client = Model & {
  __typename?: 'Client'
  id: Scalars['ID']
  email: Scalars['String']
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  zipcode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  orders?: Maybe<Array<Order>>
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type ClientInput = {
  email: Scalars['String']
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  zipcode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  update?: Maybe<Scalars['Boolean']>
}

export type Country = {
  __typename?: 'Country'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type Disclaimer = Model & {
  __typename?: 'Disclaimer'
  id: Scalars['ID']
  language: Scalars['String']
  url: Scalars['String']
  isoCode: Scalars['String']
  title: Scalars['String']
  content: Scalars['String']
  events?: Maybe<Array<Event>>
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type Event = Model & {
  __typename?: 'Event'
  id: Scalars['ID']
  type: EventType
  name: Scalars['String']
  coatings?: Maybe<Array<Scalars['String']>>
  colors?: Maybe<Array<Scalars['String']>>
  sizes?: Maybe<Array<Scalars['String']>>
  active?: Maybe<Scalars['Boolean']>
  messageTypes?: Maybe<Array<Scalars['String']>>
  presetMessages?: Maybe<Array<Scalars['String']>>
  lines?: Maybe<Array<Line>>
  shoes?: Maybe<Array<Shoe>>
  bags?: Maybe<Array<Bag>>
  disclaimers?: Maybe<Array<Disclaimer>>
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export enum EventType {
  Bags = 'BAGS',
  Shoes = 'SHOES',
  Items = 'ITEMS',
  Rtw = 'RTW',
}

export type ForgotPasswordInput = {
  email: Scalars['String']
  language: Scalars['String']
}

export type Item = Model & {
  __typename?: 'Item'
  id: Scalars['ID']
  sku: Scalars['String']
  name: Scalars['String']
  color: Scalars['String']
  order: Scalars['Int']
  packshot?: Maybe<Scalars['String']>
  line: Line
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type Line = Model & {
  __typename?: 'Line'
  id: Scalars['ID']
  name: Scalars['String']
  event: Event
  bags?: Maybe<Array<Bag>>
  readyToWears?: Maybe<Array<ReadyToWear>>
  items?: Maybe<Array<Item>>
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type LoginInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export enum MessageType {
  Initials = 'INITIALS',
  InitialsWithDots = 'INITIALS_WITH_DOTS',
  Date = 'DATE',
  Preset = 'PRESET',
  Custom = 'CUSTOM',
}

export type Model = {
  id: Scalars['ID']
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type Mutation = {
  __typename?: 'Mutation'
  createClient: Client
  submitOrder: Order
  login: TokenPayload
  refresh: TokenPayload
  forgotPassword: Scalars['Boolean']
  resetPassword: Scalars['Boolean']
  logout: Scalars['Boolean']
}

export type MutationCreateClientArgs = {
  input: ClientInput
}

export type MutationSubmitOrderArgs = {
  input: OrderInput
}

export type MutationLoginArgs = {
  input: LoginInput
}

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type Order = Model & {
  __typename?: 'Order'
  id: Scalars['ID']
  user: User
  client: Client
  customizations?: Maybe<Scalars['String']>
  hash: Scalars['String']
  signature: Scalars['String']
  product: Product
  createdAt: Scalars['DateTimeUtc']
  updatedAt: Scalars['DateTimeUtc']
  signatureUrl?: Maybe<Scalars['String']>
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']
  /** The direction that is used for ordering. */
  order: SortOrder
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Minimum. */
  Min = 'MIN',
  /** Maximum. */
  Max = 'MAX',
  /** Sum. */
  Sum = 'SUM',
  /** Amount of items. */
  Count = 'COUNT',
}

export type OrderInput = {
  type: SaleType
  clientId: Scalars['ID']
  productType: ProductType
  productId: Scalars['ID']
  customizations?: Maybe<Scalars['String']>
  signature: Scalars['String']
  signatureUrl?: Maybe<Scalars['String']>
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int']
  /** Number of nodes in the current page. */
  count: Scalars['Int']
  /** Index of the current page. */
  currentPage: Scalars['Int']
  /** Index of the last available page. */
  lastPage: Scalars['Int']
}

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo'
  /** Number of items in the current page. */
  count: Scalars['Int']
  /** Index of the current page. */
  currentPage: Scalars['Int']
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>
  /** Index of the last available page. */
  lastPage: Scalars['Int']
  /** Number of items per page. */
  perPage: Scalars['Int']
  /** Number of total available items. */
  total: Scalars['Int']
}

export type Product = Bag | Shoe | ReadyToWear | Item

export enum ProductType {
  Shoe = 'shoe',
  Bag = 'bag',
  ReadyToWear = 'readyToWear',
  Item = 'item',
}

export type Query = {
  __typename?: 'Query'
  bags: Array<Bag>
  bag?: Maybe<Bag>
  clients?: Maybe<Array<Client>>
  client?: Maybe<Client>
  disclaimers?: Maybe<Array<Disclaimer>>
  events?: Maybe<Array<Event>>
  event?: Maybe<Event>
  items: Array<Item>
  item?: Maybe<Item>
  lines: Array<Line>
  line?: Maybe<Line>
  order?: Maybe<Order>
  readyToWears: Array<ReadyToWear>
  readyToWear?: Maybe<ReadyToWear>
  shoes: Array<Shoe>
  shoe?: Maybe<Shoe>
  stores?: Maybe<Array<Store>>
  store?: Maybe<Store>
  zones?: Maybe<Array<Maybe<Zone>>>
  storesByLocations: Array<Maybe<Store>>
}

export type QueryBagArgs = {
  id: Scalars['ID']
}

export type QueryClientArgs = {
  id: Scalars['ID']
}

export type QueryEventArgs = {
  id: Scalars['ID']
}

export type QueryItemArgs = {
  id: Scalars['ID']
}

export type QueryLineArgs = {
  id: Scalars['ID']
}

export type QueryOrderArgs = {
  hash: Scalars['String']
}

export type QueryReadyToWearArgs = {
  id: Scalars['ID']
}

export type QueryShoeArgs = {
  id: Scalars['ID']
}

export type QueryStoreArgs = {
  id: Scalars['ID']
}

export type QueryStoresByLocationsArgs = {
  input: StoresByLocations
}

export type ReadyToWear = Model & {
  __typename?: 'ReadyToWear'
  id: Scalars['ID']
  sku: Scalars['String']
  name: Scalars['String']
  color: Scalars['String']
  order: Scalars['Int']
  packshot?: Maybe<Scalars['String']>
  line: Line
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type ResetPasswordInput = {
  email: Scalars['String']
  token: Scalars['String']
  password: Scalars['String']
}

export enum SaleType {
  Standard = 'STANDARD',
  Distant = 'DISTANT',
}

export type Shoe = Model & {
  __typename?: 'Shoe'
  id: Scalars['ID']
  sku: Scalars['String']
  name: Scalars['String']
  color: Scalars['String']
  order: Scalars['Int']
  packshot?: Maybe<Scalars['String']>
  sizes?: Maybe<Array<Scalars['String']>>
  event: Event
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo'
  /** Number of items in the current page. */
  count: Scalars['Int']
  /** Index of the current page. */
  currentPage: Scalars['Int']
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>
  /** Number of items per page. */
  perPage: Scalars['Int']
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']
}

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC',
}

export type Store = Model & {
  __typename?: 'Store'
  id: Scalars['ID']
  name: Scalars['String']
  location?: Maybe<Scalars['String']>
  city?: Maybe<City>
  externalId?: Maybe<Scalars['String']>
  events?: Maybe<Array<Event>>
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type StoresByLocations = {
  zone: Scalars['ID']
  country: Scalars['ID']
}

export type TokenPayload = {
  __typename?: 'TokenPayload'
  token: Scalars['String']
  expiresIn: Scalars['Int']
  expiresAt?: Maybe<Scalars['DateTimeUtc']>
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT',
}

export type User = Model & {
  __typename?: 'User'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  email: Scalars['String']
  stores?: Maybe<Array<Store>>
  createdAt?: Maybe<Scalars['DateTimeUtc']>
  updatedAt?: Maybe<Scalars['DateTimeUtc']>
}

export type Zone = {
  __typename?: 'Zone'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  countries: Array<Maybe<Country>>
}

export type AppCreateClientMutationVariables = Exact<{
  input: ClientInput
}>

export type AppCreateClientMutation = { __typename?: 'Mutation' } & {
  createClient: { __typename?: 'Client' } & Pick<
    Client,
    'id' | 'first_name' | 'last_name' | 'email' | 'address' | 'city' | 'zipcode'
  >
}

export type AppSubmitOrderMutationVariables = Exact<{
  input: OrderInput
}>

export type AppSubmitOrderMutation = { __typename?: 'Mutation' } & {
  submitOrder: { __typename?: 'Order' } & Pick<Order, 'id'>
}

export type AppGetDisclaimersQueryVariables = Exact<{ [key: string]: never }>

export type AppGetDisclaimersQuery = { __typename?: 'Query' } & {
  disclaimers?: Maybe<
    Array<
      { __typename?: 'Disclaimer' } & Pick<
        Disclaimer,
        'id' | 'language' | 'isoCode' | 'title' | 'content' | 'url'
      > & { events?: Maybe<Array<{ __typename?: 'Event' } & Pick<Event, 'type' | 'name' | 'id'>>> }
    >
  >
}

export type AppGetEventQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type AppGetEventQuery = { __typename?: 'Query' } & {
  event?: Maybe<
    { __typename?: 'Event' } & Pick<
      Event,
      'id' | 'name' | 'type' | 'coatings' | 'colors' | 'sizes' | 'messageTypes' | 'presetMessages'
    > & {
        lines?: Maybe<
          Array<
            { __typename?: 'Line' } & Pick<Line, 'id' | 'name'> & {
                bags?: Maybe<
                  Array<
                    { __typename?: 'Bag' } & Pick<Bag, 'id' | 'name' | 'color' | 'sku' | 'packshot'>
                  >
                >
                items?: Maybe<
                  Array<
                    { __typename?: 'Item' } & Pick<
                      Item,
                      'id' | 'name' | 'color' | 'sku' | 'packshot'
                    >
                  >
                >
                readyToWears?: Maybe<
                  Array<
                    { __typename?: 'ReadyToWear' } & Pick<
                      ReadyToWear,
                      'id' | 'name' | 'color' | 'sku' | 'packshot'
                    >
                  >
                >
              }
          >
        >
        shoes?: Maybe<
          Array<
            { __typename?: 'Shoe' } & Pick<
              Shoe,
              'id' | 'name' | 'color' | 'sku' | 'sizes' | 'packshot'
            >
          >
        >
      }
  >
}

export type AppGetStoreQueryVariables = Exact<{ [key: string]: never }>

export type AppGetStoreQuery = { __typename?: 'Query' } & {
  stores?: Maybe<
    Array<
      { __typename?: 'Store' } & Pick<Store, 'id' | 'name'> & {
          events?: Maybe<Array<{ __typename?: 'Event' } & Pick<Event, 'id' | 'name' | 'type'>>>
        }
    >
  >
}

export type StoresByLocationsQueryVariables = Exact<{
  input: StoresByLocations
}>

export type StoresByLocationsQuery = { __typename?: 'Query' } & {
  storesByLocations: Array<
    Maybe<{ __typename?: 'Store' } & Pick<Store, 'id' | 'name' | 'location'>>
  >
}

export type AppGetZonesQueryVariables = Exact<{ [key: string]: never }>

export type AppGetZonesQuery = { __typename?: 'Query' } & {
  zones?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Zone' } & Pick<Zone, 'id' | 'name'> & {
            countries: Array<Maybe<{ __typename?: 'Country' } & Pick<Country, 'id' | 'name'>>>
          }
      >
    >
  >
}

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput
}>

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'forgotPassword'>

export type AuthLoginMutationVariables = Exact<{
  input: LoginInput
}>

export type AuthLoginMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'TokenPayload' } & Pick<TokenPayload, 'token' | 'expiresIn' | 'expiresAt'>
}

export type AuthLogoutMutationVariables = Exact<{ [key: string]: never }>

export type AuthLogoutMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'logout'>

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput
}>

export type ResetPasswordMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'resetPassword'>
