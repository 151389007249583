import { createSelector, createSlice } from '@reduxjs/toolkit'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import type { RootState } from '../store'
import { getService } from '../../helpers/ReduxHelpers'
import { getAuthCookie } from '../../helpers/CookieHelpers'
import {
  ForgotPasswordMutationVariables,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '../../graphql/types/api'

import { actionTypes } from './types'

const loginService = getService<'login', null, { email: string; password: string }>('login', null)

const logoutService = getService<'logout'>('logout', null)

const forgetPasswordService = getService<'forgetPassword', null, ForgotPasswordMutationVariables>(
  'forgetPassword',
  null
)
const resetPasswordService = getService<'resetPassword', null, ResetPasswordMutationVariables>(
  'resetPassword',
  null
)

export type AuthState = {
  impersonate?: boolean
  // services
  logout: typeof logoutService.values
  login: typeof loginService.values
  forgetPassword: typeof forgetPasswordService.values
  resetPassword: typeof resetPasswordService.values
}

//
// Initial state
//

const initialState: AuthState = {
  impersonate: false,
  // services
  login: loginService.values,
  logout: logoutService.values,
  forgetPassword: forgetPasswordService.values,
  resetPassword: resetPasswordService.values,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setToken: (_state, _action: actionTypes.setToken) => undefined,
    setImpersonate: (state, action: actionTypes.setImpersonate) => {
      state.impersonate = action.payload
    },
    // services
    ...loginService.reducers,
    ...logoutService.reducers,
    ...forgetPasswordService.reducers,
    ...resetPasswordService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const impersonate = (state: RootState) => root(state).impersonate

const token = () => {
  const authCookie = getAuthCookie()

  return authCookie?.token || null
}

const jwt = createSelector([token], (tokenString): JwtPayload | null => {
  return tokenString ? jwtDecode<JwtPayload>(tokenString) : null
})

const isConnected = () => {
  return !!token()
}

const isExpired = createSelector([jwt], (j): boolean => {
  return !!j && !!j.exp && j.exp * 1000 <= new Date().getTime()
})

const login = (state: RootState) => root(state).login

const logout = (state: RootState) => root(state).logout

const forgetPassword = (state: RootState) => root(state).forgetPassword

const resetPassword = (state: RootState) => root(state).resetPassword

export const selectors = {
  impersonate,
  token,
  jwt,
  isConnected,
  isExpired,
  login,
  logout,
  forgetPassword,
  resetPassword,
}
