import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useParams } from 'react-router-dom'

import { selectors } from '../../redux'
import { Screen } from '../../types/global'
import * as configuration from '../../configuration'
import StoreAndEventPage from '../StoreAndEvent'
import BagCustomizationPage from '../BagCustomization'
import ShoesCustomizationPage from '../ShoesCustomization'
import SummaryPage from '../Summary'
import InformationPage from '../Information'
import i18n from '../../i18n'

const RootContainer: FC<RouteComponentProps> = () => {
  const screen = useSelector(selectors.app.screen)
  const routerParams = useParams<{ locale?: string }>()

  const [languageSet, setLanguageSet] = useState(false)

  useEffect(() => {
    console.log('useeffect')
    i18n.changeLanguage(routerParams.locale).then(() => {
      console.log('language set')
      setLanguageSet(true)
    })
  }, [])

  return (
    <>
      {languageSet && (
        <>
          {screen === Screen.home && <InformationPage />}
          {screen === Screen.store && <StoreAndEventPage />}
          {screen === Screen.BagCustomisation && <BagCustomizationPage />}
          {screen === Screen.ShoesCustomisation && <ShoesCustomizationPage />}
          {screen === Screen.Disclaimer && <SummaryPage />}
        </>
      )}
    </>
  )
}

export default memo(RootContainer)
