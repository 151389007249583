import styled, { css } from 'styled-components'

import InputLabel from '../InputLabel'
import { textStyles } from '../../../theme'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Label = styled(InputLabel)`
  margin-bottom: 4px;
`

export const Input = styled.input<{ $error?: boolean }>`
  display: block;
  width: 100%;
  max-width: 100%;
  height: 40px;
  padding: 0 12px;
  ${textStyles.fieldLabel};
  line-height: 40px;
  color: #000;
  border: 1px solid #000;
  border-radius: 0;
  box-shadow: none;
  background-color: #fff;
  outline: none;
  appearance: none;
  user-select: all;

  ${(props) =>
    props.$error &&
    css`
      color: #ff0000;
      border: 1px solid #ff0000;
    `}
`

export const Counter = styled.p`
  text-align: right;
  width: 100%;
  margin-top: 0.5rem;
  ${textStyles.tiny};
  color: #000;
`
