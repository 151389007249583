import React, { FC, InputHTMLAttributes } from 'react'

import Layout, { LayoutProps } from '../../layouts/Layout'
import { ProductDetailProps } from '../../components/ProductDetail'
import { SignatureCanvasProps } from '../../components/SignatureCanvas'
import { ActionButtonProps } from '../../components/ActionButton'

import * as SC from './styled'

export type SummaryTemplateProps = {
  className?: string
  layoutProps?: LayoutProps
  title?: string
  productDetail: ProductDetailProps
  disclaimer: {
    title: string
    text: string
  }
  otherLanguagesButton: string
  otherLanguages: {
    label: string
    value: string
  }[]
  otherLanguagesOnChange: (newValue: string) => void
  checkbox: {
    input?: InputHTMLAttributes<HTMLInputElement>
    label: string
  }
  signatureCanvas?: SignatureCanvasProps
  back: {
    label: string
    onClick?: () => void
  }
  confirmButton: ActionButtonProps
  pagination?: {
    current: number
    total: number
  }
  isSuccess?: boolean
  success?: {
    text: string
    logoutButton: ActionButtonProps
  }
}

const SummaryTemplate: FC<SummaryTemplateProps> = ({
  title,
  layoutProps,
  productDetail,
  disclaimer,
  otherLanguagesButton,
  otherLanguages,
  otherLanguagesOnChange,
  checkbox,
  signatureCanvas,
  back,
  confirmButton,
  pagination,
  isSuccess,
  success,
}) => {
  return (
    <Layout {...layoutProps}>
      <SC.Content>
        {!isSuccess && (
          <SC.Box>
            <SC.Title>{title}</SC.Title>
            <SC.Product {...productDetail} />
            <SC.DisclaimerTitle>{disclaimer.title}</SC.DisclaimerTitle>
            <SC.DisclaimerText dangerouslySetInnerHTML={{ __html: disclaimer.text }} />
            <SC.OtherLanguagesContainer>
              <SC.Dropdown
                options={otherLanguages}
                name={'other-languages'}
                placeholder={otherLanguagesButton}
                onChange={(e) => otherLanguagesOnChange(e.target.value)}
              />
            </SC.OtherLanguagesContainer>
            <SC.CheckboxContainer>
              <SC.InputCheckbox {...checkbox.input} type="checkbox" />
              <SC.InputCheckboxCheckmark />
              {checkbox.label}
            </SC.CheckboxContainer>
            <SC.Signature {...signatureCanvas} />
            <SC.Buttons>
              <SC.Back onClick={back.onClick}>{back.label}</SC.Back>
              <SC.ConfirmButton {...confirmButton} />
            </SC.Buttons>
            {pagination && (
              <SC.Pagination>{`${pagination.current}/${pagination.total}`}</SC.Pagination>
            )}
          </SC.Box>
        )}
        {isSuccess && (
          <SC.SuccessContainer>
            <SC.SuccessTitle>{success?.text}</SC.SuccessTitle>
            <SC.SuccessLogout {...success?.logoutButton} />
          </SC.SuccessContainer>
        )}
      </SC.Content>
    </Layout>
  )
}

export default SummaryTemplate
