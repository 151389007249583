import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import { selectors } from '../redux'
import { router, routesPath } from '../router'

interface AuthGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const routerParams = useParams<{ locale?: string; token?: string }>()
  const isConnected = useSelector(selectors.auth.isConnected)

  if (!routerParams.locale) {
    return <Redirect to={router(routesPath.root, { locale: 'en' })} />
  }

  if (!isConnected) {
    return <Redirect to={router(routesPath.login, { locale: routerParams.locale || 'en' })} />
  }

  return children
}

export default AuthGuard
