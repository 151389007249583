import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import SummaryTemplate, { SummaryTemplateProps } from '../../templates/Summary'
import { useHeaderProps } from '../../components/Header/useProps'
import Header from '../../components/Header'
import { actions, selectors } from '../../redux'
import { EventType, ProductType } from '../../graphql/types/api'
import { useSagaTakeEvery } from '../../hooks/useSaga'
import { Screen } from '../../types/global'

const SummaryPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // SELECTORS

  const createClient = useSelector(selectors.app.createClient)
  const getEvent = useSelector(selectors.app.getEvent)
  const disclaimers = useSelector(selectors.app.disclaimers)
  const storeAndEventStep = useSelector(selectors.app.storeAndEventStep)
  const bagCustomizationStep = useSelector(selectors.app.bagCustomizationStep)
  const shoesCustomizationStep = useSelector(selectors.app.shoesCustomizationStep)
  const routerParams = useParams<{ locale?: string; token?: string }>()
  const isLogin = window.location.href.includes('/login')

  const event = useMemo(() => getEvent.data, [getEvent.data])
  const currentEvent = storeAndEventStep.eventId

  // REF

  const $signatureCanvasRef = useRef<ReactSignatureCanvas | null>(null)

  // STATE

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [disclaimerLanguage, setDisclaimerLanguage] = useState(isLogin ? 'en' : routerParams.locale)

  // MEMO

  const article = useMemo(() => {
    switch (event?.type) {
      case EventType.Bags:
        return event?.lines
          ?.find((line) => line.id === bagCustomizationStep.line)
          ?.bags?.find(
            (bag) =>
              bag.name === bagCustomizationStep.article && bag.color === bagCustomizationStep?.color
          )
      case EventType.Items:
        return event?.lines
          ?.find((line) => line.id === bagCustomizationStep.line)
          ?.items?.find(
            (item) =>
              item.name === bagCustomizationStep.article &&
              item.color === bagCustomizationStep?.color
          )
      case EventType.Rtw:
        return event?.lines
          ?.find((line) => line.id === bagCustomizationStep.line)
          ?.readyToWears?.find(
            (readyToWear) =>
              readyToWear.name === bagCustomizationStep.article &&
              readyToWear.color === bagCustomizationStep?.color
          )
      case EventType.Shoes:
        return event?.shoes?.find(
          (shoe) =>
            shoe.name === shoesCustomizationStep.article &&
            shoe.color === shoesCustomizationStep.color
        )
    }
  }, [
    bagCustomizationStep.article,
    bagCustomizationStep?.color,
    bagCustomizationStep.line,
    event?.lines,
    event?.shoes,
    event?.type,
    shoesCustomizationStep.article,
    shoesCustomizationStep.color,
  ])

  const customization = useMemo(() => {
    switch (event?.type) {
      case EventType.Bags:
      case EventType.Rtw:
      case EventType.Items:
        return {
          size: bagCustomizationStep.fontSize,
          color: bagCustomizationStep.customizationColor,
          type: bagCustomizationStep.customizationType,
          messageType: bagCustomizationStep.customizationMessageType,
          message: bagCustomizationStep.customizationMessage,
        }
      case EventType.Shoes:
        return {
          size: shoesCustomizationStep.size,
          color: shoesCustomizationStep.color,
          left: {
            shank: shoesCustomizationStep.leftShank,
            upper1: shoesCustomizationStep.leftOneUpper,
            upper2: shoesCustomizationStep.leftTwoUpper,
          },
          right: {
            shank: shoesCustomizationStep.rightShank,
            upper1: shoesCustomizationStep.rightOneUpper,
            upper2: shoesCustomizationStep.rightTwoUpper,
          },
        }
    }
  }, [
    bagCustomizationStep.customizationColor,
    bagCustomizationStep.customizationMessage,
    bagCustomizationStep.customizationMessageType,
    bagCustomizationStep.customizationType,
    bagCustomizationStep.fontSize,
    event?.type,
    shoesCustomizationStep.color,
    shoesCustomizationStep.leftOneUpper,
    shoesCustomizationStep.leftShank,
    shoesCustomizationStep.leftTwoUpper,
    shoesCustomizationStep.rightOneUpper,
    shoesCustomizationStep.rightShank,
    shoesCustomizationStep.rightTwoUpper,
    shoesCustomizationStep.size,
  ])

  // FUNCTION

  const checkCanSubmit = useCallback(() => {
    setIsSubmitEnabled(!$signatureCanvasRef.current?.isEmpty() && isCheckboxChecked)
  }, [isCheckboxChecked])

  const disclaimersTransformer = useMemo(() => {
    const disclaimersOptions: any = []

    disclaimers?.forEach((disclaimer: any) => {
      if (
        disclaimer.events &&
        disclaimer.isoCode !== 'en' &&
        disclaimer.isoCode !== 'EN' &&
        disclaimer.isoCode !== 'fr' &&
        disclaimer.isoCode !== 'FR' &&
        disclaimer.isoCode !== 'zh_cn' &&
        disclaimer.isoCode !== 'ZH_CN' &&
        disclaimer.isoCode !== 'zh_tw' &&
        disclaimer.isoCode !== 'ZH_TW' &&
        disclaimer.isoCode !== 'JP' &&
        disclaimer.isoCode !== 'jp'
      ) {
        const isEventInDisclaimer = disclaimer.events.some(
          (event: any) => event.id === currentEvent
        )

        if (isEventInDisclaimer) {
          const option = {
            label: disclaimer.language,
            value: disclaimer.isoCode,
          }

          disclaimersOptions.push(option)
        }
      } else {
        console.warn('Disclaimer without events:', disclaimer)
      }
    })

    return disclaimersOptions
  }, [currentEvent, disclaimers])

  // PROPS
  const headerProps = useHeaderProps()
  //@ts-ignore
  const templateProps: SummaryTemplateProps = useMemo(() => {
    const selectedDisclaimerTitle =
      disclaimers &&
      disclaimers.find(
        (disclaimer) =>
          disclaimer.isoCode === disclaimerLanguage &&
          disclaimer.events?.some((event) => event.id === currentEvent)
      )?.title
    const selectedDisclaimer =
      disclaimers &&
      disclaimers.find(
        (disclaimer) =>
          disclaimer.isoCode === disclaimerLanguage &&
          disclaimer.events?.some((event) => event.id === currentEvent)
      )?.content
    return {
      layoutProps: {
        header: <Header {...headerProps} />,
      },
      title: t('summary.title'),
      productDetail: {
        image: {
          src: article?.packshot,
        },
        type: event?.type,
        title: article?.name ?? '',
        details: [
          article?.color ?? '',
          [EventType.Bags, EventType.Items, EventType.Rtw].includes(event?.type as EventType)
            ? customization?.color ?? ''
            : null,
          customization?.size ?? '',
        ],
        customization: customization,
      },
      disclaimer: {
        title: selectedDisclaimerTitle,
        text: selectedDisclaimer,
      },
      otherLanguagesButton: t('summary.otherLanguagesButton.label'),
      otherLanguages: disclaimersTransformer,
      otherLanguagesOnChange(newValue) {
        setDisclaimerLanguage(newValue)
      },
      checkbox: {
        label: t('summary.checkbox.label'),
        input: {
          checked: isCheckboxChecked,
          onChange: (e) => {
            setIsCheckboxChecked(e.target.checked)
          },
        },
      },
      signatureCanvas: {
        indicationText: t('summary.signature.indication'),
        onRef: (ref) => {
          $signatureCanvasRef.current = ref
        },
        onClear: () => {
          setIsSubmitEnabled(false)
        },
        reactSignatureCanvas: {
          onEnd: () => {
            checkCanSubmit()
          },
        },
      },
      back: {
        label: t('summary.back'),
        onClick: () => {
          switch (event?.type) {
            case EventType.Bags:
            case EventType.Rtw:
            case EventType.Items:
              dispatch(actions.app.setScreen({ screen: Screen.BagCustomisation }))
              break
            case EventType.Shoes:
              dispatch(actions.app.setScreen({ screen: Screen.ShoesCustomisation }))
              break
            default:
              dispatch(actions.app.setScreen({ screen: Screen.home }))
          }
        },
      },
      confirmButton: {
        text: t('summary.submitButton'),
        disabled: !isSubmitEnabled,
        onClick: () => {
          const productTypes = {
            ['Bag']: ProductType.Bag,
            ['Shoe']: ProductType.Shoe,
            ['Item']: ProductType.Item,
            ['ReadyToWear']: ProductType.ReadyToWear,
          }

          let customizations
          switch (event?.type) {
            case EventType.Bags:
            case EventType.Items:
            case EventType.Rtw:
              customizations = JSON.stringify(bagCustomizationStep)
              break
            case EventType.Shoes:
              customizations = JSON.stringify(shoesCustomizationStep)
              break
          }

          dispatch(
            actions.app.submitOrderRequest({
              input: {
                clientId: createClient.data!.id,
                type: storeAndEventStep.saleType!,
                productId: article!.id,
                productType: productTypes[article!.__typename!],
                signature: $signatureCanvasRef.current!.toDataURL(),
                customizations,
              },
            })
          )
        },
      },
      pagination: {
        current: 4,
        total: 4,
      },
      isSuccess,
      success: {
        text: t('success.text'),
        logoutButton: {
          text: t('logout.label'),
          onClick: () => {
            dispatch(actions.auth.logoutRequest(null))
          },
        },
      },
    }
  }, [
    article,
    bagCustomizationStep,
    checkCanSubmit,
    createClient.data,
    currentEvent,
    customization,
    disclaimerLanguage,
    disclaimers,
    disclaimersTransformer,
    dispatch,
    event?.type,
    headerProps,
    isCheckboxChecked,
    isSubmitEnabled,
    isSuccess,
    shoesCustomizationStep,
    storeAndEventStep.saleType,
    t,
  ])

  // EFFECT

  useEffect(() => {
    checkCanSubmit()
  }, [checkCanSubmit])

  // SAGA

  useSagaTakeEvery(
    actions.app.submitOrderSuccess,
    useCallback(() => {
      setIsSuccess(true)
    }, [])
  )

  // TODO: redirect to home if no data in store

  // RETURN

  return <SummaryTemplate {...templateProps} />
}

export default SummaryPage
