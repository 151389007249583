import React, { FC, ReactNode } from 'react'

import * as SC from './styled'

export type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  id?: string
  label?: string
  name: string
  value?: string
  maxLength?: number
  placeholder?: string
  required?: boolean
  disabled?: boolean
  error?: string
  showError?: boolean
  showCounter?: boolean
  helperText?: string
  helperTextSide?: 'start' | 'end'
  children?: ReactNode
}

const TextField: FC<TextFieldProps> = ({
  className,
  id,
  name,
  label,
  placeholder,
  value,
  maxLength,
  required = false,
  disabled = false,
  error,
  showError = true,
  showCounter,
  helperText,
  helperTextSide = 'end',
  onChange,
  ...inputProps
}) => {
  return (
    <SC.Wrapper className={className}>
      {label && (
        <SC.Label required={required} error={!!error}>
          <div>{label}</div>
        </SC.Label>
      )}
      <SC.Input
        id={id}
        name={name}
        value={value}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        $error={!!error}
        {...inputProps}
      />
      {showCounter && (
        <SC.Counter>
          {' '}
          {value?.length} / {maxLength}
        </SC.Counter>
      )}
    </SC.Wrapper>
  )
}

export default TextField
