import React from 'react'

import { FormSubmitErrorsProps } from '../../form/FormSubmitErrors'
import FormikField from '../../form/FormikField'
import { SubmitButtonProps } from '../../form/SubmitButton'
import { FormikFormProps } from '../../form/FormikForm'
import { SelectProps } from '../../form/Select'
import { TextFieldProps } from '../../form/TextField'

import * as SC from './styled'

export type BagCustomizationFormValues = {
  line: string
  article: string
  color: string
  customizationType: string
  customizationColor: string
  fontSize: string
  customizationMessageType: string
  customizationMessage: string
}

export type BagCustomizationFormProps = {
  className?: string
  formik: FormikFormProps<BagCustomizationFormValues>
  fields: {
    line?: SelectProps
    article?: SelectProps
    color?: SelectProps
    customizationType?: SelectProps
    customizationColor?: SelectProps
    fontSize?: SelectProps
    customizationMessageType?: SelectProps
    customizationMessage?: {
      component: React.ComponentType<TextFieldProps> | React.ComponentType<SelectProps>
      props: TextFieldProps | SelectProps
    }
  }
  customizationLabel?: string
  back?: {
    label: string
    onClick?: () => void
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: SubmitButtonProps
}

const BagCustomizationForm: React.FC<BagCustomizationFormProps> = ({
  className,
  formik,
  fields,
  customizationLabel,
  submitErrors,
  back,
  submitButton,
}) => {
  return (
    <SC.Form {...formik} className={className}>
      <SC.Fields>
        {fields.line && <FormikField {...fields.line} Component={SC.SelectField} />}
        {fields.article && <FormikField {...fields.article} Component={SC.SelectField} />}
        {fields.color && <FormikField {...fields.color} Component={SC.SelectField} />}
      </SC.Fields>

      {(fields.customizationType ||
        fields.customizationColor ||
        fields.fontSize ||
        fields.customizationMessageType) && (
        <SC.Group>
          <SC.GroupLabel>{customizationLabel}</SC.GroupLabel>
          <SC.Fields>
            {fields.customizationType && (
              <FormikField {...fields.customizationType} Component={SC.SelectField} />
            )}
            {fields.customizationColor && (
              <FormikField {...fields.customizationColor} Component={SC.SelectField} />
            )}
            {fields.fontSize && <FormikField {...fields.fontSize} Component={SC.SelectField} />}
            {fields.customizationMessageType && (
              <FormikField {...fields.customizationMessageType} Component={SC.SelectField} />
            )}
            {fields.customizationMessage && (
              <FormikField
                {...fields.customizationMessage.props}
                Component={fields.customizationMessage.component}
              />
            )}
          </SC.Fields>
        </SC.Group>
      )}

      {submitErrors && <SC.SubmitErrors errors={submitErrors} />}
      <SC.Buttons>
        {back && <SC.Back onClick={back.onClick}>{back.label}</SC.Back>}
        <SC.Submit {...submitButton} />
      </SC.Buttons>
    </SC.Form>
  )
}

export default BagCustomizationForm
