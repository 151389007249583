import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.UniversBoldCond}
    font-size: 3rem;
    text-transform: uppercase;
    line-height: initial;
  `,
  titleH2: `
    font-family: "Arial", 'sans-serif';
    font-weight: 500;
    font-size: 1.8rem;
    line-height: initial;
    text-transform: uppercase;
  `,
  titleH3: `
    ${fonts.UniversBoldCond}
    font-size: 1.8rem;
    line-height: initial;
    text-transform: uppercase;
  `,
  text: `
    ${fonts.UniversRoman}
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: 0.03em;
  `,
  tiny: `
    ${fonts.UniversRoman}
    font-size: 1.2rem;
    line-height: 1.5;
  `,
  // Customisation
  fieldLabel: `
    ${fonts.UniversRoman}
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: 0.05em;
  `,
}

export default textStyles
