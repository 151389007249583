import styled from 'styled-components'

import breakpoints from '../../theme/breakpoints'
import fonts from '../../theme/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  gap: 2.5rem;

  ${breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const ImageContainer = styled.div`
  width: 17.6rem;
  height: 17.6rem;
  border: 1px solid ${({ theme }) => theme.palette.colors.pureBlack};
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const TextContainer = styled.div`
  text-align: left;
  align-self: stretch;
`

export const Name = styled.p`
  ${fonts.UniversBoldCond}
  font-size: 2.2rem;
  line-height: normal;
`

export const DataBlock = styled.div`
  margin-top: 0.6rem;
  ${fonts.UniversRoman}
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.05em;
`

export const CustomizationBlock = styled.div`
  margin-top: 1rem;
  ${fonts.UniversRoman}
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.05em;
`

export const Customization = styled.p`
  margin-top: 1rem;
  ${fonts.UniversRoman}
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.05em;
`

export const CustomizationLabel = styled.p`
  font-size: 1.2rem;
  color: #8d8d8d;
`

export const CustomizationShoes = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CustomizationSide = styled.div``

export const DataLabel = styled.p``
