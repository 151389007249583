import React, { FC } from 'react'

import Layout, { LayoutProps } from '../../layouts/Layout'
import { InformationFormProps } from '../../components/forms/InformationForm'

import * as SC from './styled'

export type InformationTemplateProps = {
  className?: string
  layoutProps?: LayoutProps
  title: string
  form: InformationFormProps
}

const InformationTemplate: FC<InformationTemplateProps> = ({ layoutProps, title, form }) => {
  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <SC.Box>
          <SC.Title>{title}</SC.Title>
          <SC.Form {...form} />
        </SC.Box>
      </SC.Content>
    </Layout>
  )
}

export default InformationTemplate
