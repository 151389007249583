import { all, put, call, spawn, takeLeading, delay } from 'redux-saga/effects'

import { actions } from '..'
import { services } from '../../graphql'
import { getAuthCookie, removeAuthCookie, setAuthCookie } from '../../helpers/CookieHelpers'
import ApiSagas from '../api/sagas'
import { ApiResponse } from '../api/types/state'
import { AuthLoginMutationVariables } from '../../graphql/types/api'
import AppSagas from '../app/sagas'

import { actionTypes } from './types'

export default class AuthSagas {
  static *onInit() {
    const cookie = getAuthCookie()
    if (cookie) {
      yield put(actions.auth.setImpersonate(cookie?.impersonate ?? false))
      // yield put(actions.auth.setToken({ token: cookie.token }))
    }
  }

  static *onLoginRequest({ payload }: ReturnType<typeof actions.auth.loginRequest>) {
    const response: ApiResponse<typeof services.auth.mutations.login> = yield call(
      ApiSagas.mutate,
      services.auth.mutations.login,
      {
        input: {
          email: payload.email,
          password: payload.password,
        },
      } as AuthLoginMutationVariables
    )

    if (response.errors) {
      yield put(actions.auth.loginError(response.errors))
    }

    if (response.data) {
      console.log(response.data)

      yield put(
        actions.auth.setToken({
          token: response.data.token,
          expireAt: response.data.expiresAt,
          setCookies: true,
        })
      )

      yield spawn(AppSagas.loadAuthData)

      yield put(actions.auth.loginSuccess(null))
    }
  }

  static *onLogoutRequest() {
    const response: ApiResponse<typeof services.auth.mutations.logout> = yield call(
      ApiSagas.mutate,
      services.auth.mutations.logout
    )

    if (response.errors) {
      yield put(actions.auth.logoutError(response.errors))
    }

    if (response.data) {
      yield put(actions.auth.logoutSuccess(null))
      yield put(actions.auth.resetAuth())
    }
  }

  static onSetToken({ payload }: actionTypes.setToken) {
    if (payload.setCookies) {
      setAuthCookie(
        { token: payload.token },
        { expires: payload.expireAt ? new Date(payload.expireAt) : undefined }
      )
    }
  }

  static *onForgotPasswordRequest({
    payload,
  }: ReturnType<typeof actions.auth.forgetPasswordRequest>) {
    const response: ApiResponse<typeof services.auth.mutations.forgotPassword> = yield call(
      ApiSagas.mutate,
      services.auth.mutations.forgotPassword,
      payload
    )

    if (response.errors) {
      yield put(actions.auth.forgetPasswordError(response.errors))
    }

    if (response.data) {
      yield put(actions.auth.forgetPasswordSuccess(null))
    }
  }

  static *onResetPasswordRequest({
    payload,
  }: ReturnType<typeof actions.auth.resetPasswordRequest>) {
    const response: ApiResponse<typeof services.auth.mutations.resetPassword> = yield call(
      ApiSagas.mutate,
      services.auth.mutations.resetPassword,
      payload
    )

    if (response.errors) {
      yield put(actions.auth.resetPasswordError(response.errors))
    }

    if (response.data) {
      yield put(actions.auth.resetPasswordSuccess(null))
    }
  }

  static *onResetAuth() {
    removeAuthCookie()
    yield put(actions.app.resetSteps())
  }

  static *loop() {
    yield all([
      takeLeading(actions.auth.loginRequest, this.onLoginRequest),
      takeLeading(actions.auth.logoutRequest, this.onLogoutRequest),
      takeLeading(actions.auth.setToken, this.onSetToken),
      takeLeading(actions.auth.resetAuth, this.onResetAuth),
      takeLeading(actions.auth.forgetPasswordRequest, this.onForgotPasswordRequest),
      takeLeading(actions.auth.resetPasswordRequest, this.onResetPasswordRequest),
    ])
  }
}
