import React, { FC, useRef } from 'react'
import cx from 'classnames'
import ReactSignatureCanvas, { ReactSignatureCanvasProps } from 'react-signature-canvas'

import { Icons } from '../Icon/types'

import * as SC from './styled'

export type SignatureCanvasProps = {
  className?: string
  indicationText?: string
  reactSignatureCanvas?: ReactSignatureCanvasProps
  onRef?: (ref: ReactSignatureCanvas | null) => void
  onClear?: () => void
}

const SignatureCanvas: FC<SignatureCanvasProps> = ({
  className,
  indicationText,
  reactSignatureCanvas,
  onRef,
  onClear,
}) => {
  const $signatureCanvas = useRef<ReactSignatureCanvas | null>(null)

  return (
    <SC.Container className={cx(className)}>
      <SC.CanvasContainer>
        <ReactSignatureCanvas
          ref={(ref) => {
            $signatureCanvas.current = ref
            onRef?.(ref)
          }}
          minWidth={1.5}
          maxWidth={3}
          backgroundColor="rgb(222,222,222)"
          penColor="#000"
          clearOnResize
          canvasProps={{
            style: {
              width: '100%',
              height: '200px',
            },
          }}
          {...reactSignatureCanvas}
        />
        {indicationText && <SC.IndicationText>{indicationText}</SC.IndicationText>}
      </SC.CanvasContainer>
      <SC.RefreshIcon
        icon={Icons.refresh}
        onClick={() => {
          onClear?.()
          $signatureCanvas.current?.clear()
        }}
      />
    </SC.Container>
  )
}

export default SignatureCanvas
