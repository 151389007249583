import { generatePath } from 'react-router-dom'
import { ExtractRouteParams } from 'react-router'

export const router = <S extends string>(route: string, params?: ExtractRouteParams<S>): string => {
  return generatePath(route, params)
}

export const routesPath = {
  root: `/:locale?`,
  login: '/:locale/login',
  reset: '/:locale/reset/:token',
}
