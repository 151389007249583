import React from 'react'

import { FormSubmitErrorsProps } from '../../form/FormSubmitErrors'
import { TextFieldProps } from '../../form/TextField'
import FormikField from '../../form/FormikField'
import { SubmitButtonProps } from '../../form/SubmitButton'
import { FormikFormProps } from '../../form/FormikForm'

import * as SC from './styled'

type InformationFormValues = {
  first_name: string
  last_name: string
  address: string
  city: string
  email: string
}

export type InformationFormProps = {
  className?: string
  formik: FormikFormProps<InformationFormValues>
  fields: {
    first_name: TextFieldProps
    last_name: TextFieldProps
    address: TextFieldProps
    city: TextFieldProps
    email: TextFieldProps
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: SubmitButtonProps
}

const InformationForm: React.FC<InformationFormProps> = ({
  className,
  formik,
  fields,
  submitErrors,
  submitButton,
}) => {
  return (
    <SC.Form {...formik} className={className}>
      <FormikField {...fields.first_name} Component={SC.TextField} />
      <FormikField {...fields.last_name} Component={SC.TextField} />
      <FormikField {...fields.address} Component={SC.TextField} />
      <FormikField {...fields.city} Component={SC.TextField} />
      <FormikField {...fields.email} Component={SC.TextField} />
      {submitErrors && <SC.SubmitErrors errors={submitErrors} />}
      <SC.Submit {...submitButton} />
    </SC.Form>
  )
}

export default InformationForm
