import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { router, routesPath } from '../router'
import { NotFoundTemplateProps } from '../templates/NotFound'

const useNotFound = (): NotFoundTemplateProps => {
  const { t } = useTranslation()

  return useMemo(
    () => ({
      title: t('notFound.title'),
      cta: {
        text: t('notFound.cta'),
        link: {
          link: router(routesPath.root),
        },
      },
    }),
    [t]
  )
}

export default useNotFound
