import React from 'react'

import { FormSubmitErrorsProps } from '../../form/FormSubmitErrors'
import { TextFieldProps } from '../../form/TextField'
import FormikField from '../../form/FormikField'
import { SubmitButtonProps } from '../../form/SubmitButton'
import { FormikFormProps } from '../../form/FormikForm'

import * as SC from './styled'

export type ResetFormValues = {
  email: string
  password: string
}

export type ResetFormProps = {
  className?: string
  formik: FormikFormProps<ResetFormValues>
  text?: string
  fields: {
    email: TextFieldProps
    password: TextFieldProps
    passwordConfirmation: TextFieldProps
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: SubmitButtonProps
  backButton?: SubmitButtonProps
}

const ResetForm: React.FC<ResetFormProps> = ({
  className,
  text,
  formik,
  fields,
  submitErrors,
  submitButton,
}) => {
  console.log(formik)
  return (
    <SC.Form {...formik} className={className}>
      <>
        <FormikField {...fields.email} Component={SC.TextField} />
        <SC.Text dangerouslySetInnerHTML={{ __html: text }}></SC.Text>
        <FormikField {...fields.password} Component={SC.TextField} type="password" />
        <FormikField {...fields.passwordConfirmation} Component={SC.TextField} type="password" />
        {submitErrors && <SC.SubmitErrors errors={submitErrors} />}
        <SC.Submit {...submitButton} />
      </>
    </SC.Form>
  )
}

export default ResetForm
