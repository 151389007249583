import styled, { css } from 'styled-components'

import Icon from '../Icon'
import LinkC from '../Link'

export const Link = styled(LinkC)`
  display: inline-flex;
  width: 100%;
  text-decoration: none;
`

export const Button = styled.button<{ disabled?: boolean; $outlined?: boolean }>`
  padding: 1rem 2rem;
  border-radius: 3rem;
  width: 100%;
  color: black;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: black;
    color: white;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
      opacity: 0.5;
      color: black !important;
      background-color: white !important;
    `}
`

export const IconIn = styled(Icon)<{ iconSide?: string }>`
  width: 1.8rem;
  height: 1.8rem;
  margin-left: ${(props) => (props.iconSide === 'right' ? '1rem' : '0')};
  margin-right: ${(props) => (props.iconSide === 'left' ? '1rem' : '0')};
`
