import React, { FC } from 'react'

import Layout, { LayoutProps } from '../../layouts/Layout'
import { ShoesCustomizationFormProps } from '../../components/forms/ShoesCustomizationForm'

import * as SC from './styled'

export type ShoesCustomizationTemplateProps = {
  className?: string
  layoutProps?: LayoutProps
  title?: string
  form: ShoesCustomizationFormProps
  pagination?: {
    current: number
    total: number
  }
}

const ShoesCustomizationTemplate: FC<ShoesCustomizationTemplateProps> = ({
  title,
  layoutProps,
  form,
  pagination,
}) => {
  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <SC.Box>
          <SC.Title>{title}</SC.Title>
          <SC.Form {...form} />
          {pagination && (
            <SC.Pagination>{`${pagination.current}/${pagination.total}`}</SC.Pagination>
          )}
        </SC.Box>
      </SC.Content>
    </Layout>
  )
}

export default ShoesCustomizationTemplate
