import gql from 'graphql-tag'

import { AuthLoginMutation } from '../../../types/api'

export default {
  mutation: gql`
    mutation AuthLogin($input: LoginInput!) {
      login(input: $input) {
        token
        expiresIn
        expiresAt
      }
    }
  `,
  transformer: (response: AuthLoginMutation) => {
    return response.login
  },
}
