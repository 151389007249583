import styled from 'styled-components'

import TextFieldC from '../../form/TextField'
import FormSubmitErrors from '../../form/FormSubmitErrors'
import SubmitButton from '../../form/SubmitButton'
import FormikForm from '../../form/FormikForm'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TextField = styled(TextFieldC)`
  &:not(:first-child) {
    margin-top: 15px;
  }
`

export const SubmitErrors = styled(FormSubmitErrors)`
  margin-top: 30px;
`

export const Submit = styled(SubmitButton)`
  width: 100%;
  max-width: 250px;
  margin-top: 55px;
`
