import styled, { css } from 'styled-components'
import { Link as DomLinkC } from 'react-router-dom'

export const Link = styled.a`
  color: black;
`

export const DomLink = styled(DomLinkC)`
  color: black;
  text-decoration: none;
`
export const Span = styled.span<{ $button?: boolean }>`
  ${({ $button }) =>
    $button &&
    css`
      cursor: pointer;
    `}
`
