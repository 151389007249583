import React, { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import InformationTemplate, { InformationTemplateProps } from '../../templates/Information'
import { useHeaderProps } from '../../components/Header/useProps'
import Header from '../../components/Header'
import { actions, selectors } from '../../redux'
import { useSagaTakeEvery } from '../../hooks/useSaga'
import { Screen } from '../../types/global'

const InformationPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // SELECTOR

  const createClientService = useSelector(selectors.app.createClient)

  // PROPS

  const headerProps = useHeaderProps()

  const templateProps: InformationTemplateProps = useMemo(() => {
    return {
      layoutProps: {
        header: <Header {...headerProps} />,
      },
      title: t('information.title'),
      form: {
        formik: {
          validationSchema: Yup.object().shape({
            first_name: Yup.string().required(),
            last_name: Yup.string().required(),
            address: Yup.string().required(),
            city: Yup.string().required(),
            email: Yup.string().required().email(),
          }),
          initialValues: {
            first_name: createClientService.data?.first_name ?? '',
            last_name: createClientService.data?.last_name ?? '',
            address: createClientService.data?.address ?? '',
            city: createClientService.data?.city ?? '',
            email: createClientService.data?.email ?? '',
          },
          onSubmit: (values) => {
            console.log(values)
            dispatch(
              actions.app.createClientRequest({
                input: {
                  first_name: values.first_name,
                  last_name: values.last_name,
                  address: values.address,
                  city: values.city,
                  zipcode: values.city,
                  email: values.email,
                },
              })
            )
          },
        },
        fields: {
          first_name: {
            name: 'first_name',
            placeholder: t('information.form.first_name.placeholder'),
            required: true,
          },
          last_name: {
            name: 'last_name',
            placeholder: t('information.form.last_name.placeholder'),
            required: true,
          },
          address: {
            name: 'address',
            placeholder: t('information.form.address.placeholder'),
            required: true,
          },
          city: {
            name: 'city',
            placeholder: t('information.form.city.placeholder'),
            required: true,
          },
          email: {
            name: 'email',
            placeholder: t('information.form.email.placeholder'),
            required: true,
          },
        },
        submitButton: {
          text: t('information.submitButton'),
          isPending: createClientService.pending,
          isSuccess: createClientService.success,
        },
      },
    }
  }, [
    createClientService.data?.address,
    createClientService.data?.city,
    createClientService.data?.email,
    createClientService.data?.first_name,
    createClientService.data?.last_name,
    createClientService.pending,
    createClientService.success,
    dispatch,
    headerProps,
    t,
  ])

  // SAGA

  useSagaTakeEvery(
    actions.app.createClientSuccess,
    useCallback(() => {
      dispatch(actions.app.setScreen({ screen: Screen.store }))
    }, [dispatch])
  )

  // RETURN

  return <InformationTemplate {...templateProps} />
}

export default InformationPage
