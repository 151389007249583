import { FC, useEffect } from 'react'
import { FormikContextType, useFormikContext } from 'formik'

export type FormikContextObserverProps<Values = any> = {
  onUpdate?: (context: FormikContextType<Values>) => void
}

const FormikContextObserver: FC<FormikContextObserverProps> = ({ onUpdate }) => {
  const context = useFormikContext()
  useEffect(() => {
    onUpdate?.(context)
  }, [context, onUpdate])
  return null
}

export default FormikContextObserver
