const values = {
  xs: 0,
  sm: 600,
  md: 1060,
  lg: 1280,
  xl: 1440,
}
const up = (val: keyof typeof values) => `@media (min-width: ${values?.[val]}px)`
const down = (val: keyof typeof values) => `@media (max-width: ${values?.[val]}px)`

const breakpoints = {
  values,
  up,
  down,
}

export default breakpoints
