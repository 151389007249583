import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import { selectors } from '../redux'
import { router, routesPath } from '../router'

interface GuestGuardType {
  children: React.ReactNode
}

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const routerParams = useParams<{ locale?: string; token?: string }>()
  const isConnected = useSelector(selectors.auth.isConnected)

  if (routerParams.token) {
    return children
  }

  if (!routerParams.locale) {
    return <Redirect to={router(routesPath.root, { locale: 'en' })} />
  }

  if (isConnected) {
    return <Redirect to={router(routesPath.root, { locale: routerParams.locale })} />
  }

  return children
}

export default GuestGuard
