import gql from 'graphql-tag'

import { StoresByLocationsQuery } from '../../../types/api'

export default {
  query: gql`
    query storesByLocations($input: StoresByLocations!) {
      storesByLocations(input: $input) {
        id
        name
        location
      }
    }
  `,
  transformer: (response: StoresByLocationsQuery) => {
    return response.storesByLocations
  },
}
