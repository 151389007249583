import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import * as SC from './styled'

export type LinkProps = {
  className?: string
  href?: string
  target?: string
  title?: string
  role?: string
  tabIndex?: number
  rel?: string
  onClick?: (e: any | undefined) => void
  text?: string
  link?: string
  route?: string
}

const Link: FC<LinkProps> = (props) => {
  const { className, children, target, title, role, tabIndex, rel, onClick, text } = props

  const handleClick = useCallback(
    (e) => {
      onClick?.(e)
    },
    [onClick]
  )

  const href = props?.href || props?.link

  return (
    <>
      {href &&
        (href?.indexOf('http') !== 0 ? (
          <SC.DomLink
            className={cx(
              className,
              'MuiTypography-root',
              'MuiLink-root',
              'MuiLink-underlineHover'
            )}
            to={href}
            {...(tabIndex && { tabIndex })}
            {...(target && { target })}
            {...(title && { title })}
            {...(role && { role })}
            {...(rel && { rel })}
            {...(onClick && { onClick: handleClick })}
          >
            {children || text}
          </SC.DomLink>
        ) : (
          <SC.Link
            className={`${className}`}
            href={href}
            {...(tabIndex && { tabIndex })}
            {...(target && { target })}
            {...(title && { title })}
            {...(role && { role })}
            {...(rel && { rel })}
            {...(onClick && { onClick: handleClick })}
          >
            {children || text}
          </SC.Link>
        ))}
      {!href && (
        <SC.Span
          className={`${className} is-span`}
          $button={onClick !== undefined}
          onClick={handleClick}
        >
          {children || text}
        </SC.Span>
      )}
    </>
  )
}

export default Link
