import { RouteType } from '../types/routes'
import LoginPage from '../pages/Login'
import GuestGuard from '../components/GuestGuard'
import InformationPage from '../pages/Information'
import BagCustomizationPage from '../pages/BagCustomization'
import ShoesCustomizationPage from '../pages/ShoesCustomization'
import StoreAndEventPage from '../pages/StoreAndEvent'
import SummaryPage from '../pages/Summary'
import AuthGuard from '../components/AuthGuard'
import Root from '../pages/Root'
import RootContainer from '../pages/Root'
import ResetPage from '../pages/Reset'

import { routesPath } from './index'

const publicRoutes: RouteType = {
  path: '',
  component: null,
  guard: GuestGuard,
  children: [
    {
      path: routesPath.login,
      name: 'Login',
      component: LoginPage,
    },
    {
      path: routesPath.reset,
      name: 'Reset',
      component: ResetPage,
    },
  ],
}

const privateRoutes: RouteType = {
  path: '',
  component: null,
  guard: AuthGuard,
  children: [
    {
      path: routesPath.root,
      name: 'Root',
      component: RootContainer,
    },
  ],
}

export const defaultLayoutRoutes = [publicRoutes, privateRoutes]
