import styled from 'styled-components'

import { textStyles } from '../../theme'
import LoginForm from '../../components/forms/LoginForm'

export const Content = styled.div`
  padding: 4rem 0;
  flex-grow: 1;
`

export const Box = styled.div`
  max-width: 335px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  ${textStyles.titleH1};
  text-align: center;
`

export const Form = styled(LoginForm)`
  margin-top: 55px;
  width: 100%;
`
