import React, { FC } from 'react'
import cx from 'classnames'

import { Icons } from '../Icon/types'
import { SelectProps } from '../form/Select'

import * as SC from './styled'

export type HeaderProps = {
  className?: string
  languageSelect?: SelectProps
  onLogoClick?: () => void
}

const Header: FC<HeaderProps> = ({ className, languageSelect, onLogoClick }) => {
  return (
    <SC.Container className={cx(className)}>
      <SC.LogoIcon icon={Icons.logo} onClick={onLogoClick} />
      {languageSelect && <SC.LanguageSelector {...languageSelect} hasBorder={false} />}
    </SC.Container>
  )
}

export default Header
