import React from 'react'

import { FormSubmitErrorsProps } from '../../form/FormSubmitErrors'
import FormikField from '../../form/FormikField'
import { SubmitButtonProps } from '../../form/SubmitButton'
import { FormikFormProps } from '../../form/FormikForm'
import { SelectProps } from '../../form/Select'
import { TextFieldProps } from '../../form/TextField'

import * as SC from './styled'

export type ShoesCustomizationFormValues = {
  article: string
  color: string
  size: string
  leftShank: string
  leftOneUpper: string
  leftTwoUpper: string
  rightShank: string
  rightOneUpper: string
  rightTwoUpper: string
}

export type ShoesCustomizationFormProps = {
  className?: string
  formik: FormikFormProps<ShoesCustomizationFormValues>
  fields: {
    article?: SelectProps
    color?: SelectProps
    size?: SelectProps
    leftShank?: TextFieldProps
    leftOneUpper?: TextFieldProps
    leftTwoUpper?: TextFieldProps
    rightShank?: TextFieldProps
    rightOneUpper?: TextFieldProps
    rightTwoUpper?: TextFieldProps
  }
  leftLabel?: string
  rightLabel?: string
  back?: {
    label: string
    onClick?: () => void
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: SubmitButtonProps
}

const ShoesCustomizationForm: React.FC<ShoesCustomizationFormProps> = ({
  className,
  formik,
  fields,
  leftLabel,
  rightLabel,
  submitErrors,
  back,
  submitButton,
}) => {
  return (
    <SC.Form {...formik} className={className}>
      {fields.article && <FormikField {...fields.article} Component={SC.SelectField} />}
      {fields.color && <FormikField {...fields.color} Component={SC.SelectField} />}
      {fields.size && <FormikField {...fields.size} Component={SC.SelectField} />}

      {(fields.leftShank || fields.leftOneUpper || fields.leftTwoUpper) && (
        <SC.Group>
          <SC.GroupLabel>{leftLabel}</SC.GroupLabel>
          {fields.leftShank && <FormikField {...fields.leftShank} Component={SC.TextFieldField} />}
          {fields.leftOneUpper && (
            <FormikField {...fields.leftOneUpper} Component={SC.TextFieldField} />
          )}
          {fields.leftTwoUpper && (
            <FormikField {...fields.leftTwoUpper} Component={SC.TextFieldField} />
          )}
        </SC.Group>
      )}

      {(fields.rightShank || fields.rightOneUpper || fields.rightTwoUpper) && (
        <SC.Group>
          <SC.GroupLabel>{rightLabel}</SC.GroupLabel>
          {fields.rightShank && (
            <FormikField {...fields.rightShank} Component={SC.TextFieldField} />
          )}
          {fields.rightOneUpper && (
            <FormikField {...fields.rightOneUpper} Component={SC.TextFieldField} />
          )}
          {fields.rightTwoUpper && (
            <FormikField {...fields.rightTwoUpper} Component={SC.TextFieldField} />
          )}
        </SC.Group>
      )}

      {submitErrors && <SC.SubmitErrors errors={submitErrors} />}
      <SC.Buttons>
        {back && <SC.Back onClick={back.onClick}>{back.label}</SC.Back>}
        <SC.Submit {...submitButton} />
      </SC.Buttons>
    </SC.Form>
  )
}

export default ShoesCustomizationForm
