import React, { useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ShoesCustomizationTemplate, {
  ShoesCustomizationTemplateProps,
} from '../../templates/ShoesCustomization'
import { useHeaderProps } from '../../components/Header/useProps'
import Header from '../../components/Header'
import { ShoesCustomizationFormValues } from '../../components/forms/ShoesCustomizationForm'
import { actions, selectors } from '../../redux'
import { Screen } from '../../types/global'

const ShoesCustomizationPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // SELECTORS

  const getEvent = useSelector(selectors.app.getEvent)
  const event = useMemo(() => getEvent.data, [getEvent.data])
  const shoesCustomizationStep = useSelector(selectors.app.shoesCustomizationStep)

  // REF

  const $formikRef = useRef<FormikProps<ShoesCustomizationFormValues>>(null)

  // STATE

  //const [shoesCustomizationStep, setShoesCustomizationStep] = useState({})

  const [fieldsVisibility, setFieldsVisibility] = useState({
    article: true,
    color: !!shoesCustomizationStep?.color,
    size: !!shoesCustomizationStep?.size,
    leftShank: !!shoesCustomizationStep?.size,
    leftOneUpper: !!shoesCustomizationStep?.size,
    leftTwoUpper: !!shoesCustomizationStep?.size,
    rightShank: !!shoesCustomizationStep?.size,
    rightOneUpper: !!shoesCustomizationStep?.size,
    rightTwoUpper: !!shoesCustomizationStep?.size,
  })

  const [selectedArticle, setSelectedArticle] = useState(shoesCustomizationStep.article)
  const [selectedColor, setSelectedColor] = useState(shoesCustomizationStep.color)

  // MEMO

  const articlesOptions = useMemo(() => {
    const articleNames = event?.shoes?.map((article) => article.name)
    if (articleNames?.find((elem) => elem === shoesCustomizationStep.article)) {
      return Array.from(new Set(articleNames))
    } else {
      articleNames &&
        setTimeout(() => {
          $formikRef.current?.setValues({
            article: articleNames?.sort()[0],
            color: '',
            size: '',
            leftShank: shoesCustomizationStep?.leftShank ?? '',
            leftOneUpper: shoesCustomizationStep?.leftOneUpper ?? '',
            leftTwoUpper: shoesCustomizationStep?.leftTwoUpper ?? '',
            rightShank: shoesCustomizationStep?.rightShank ?? '',
            rightOneUpper: shoesCustomizationStep?.rightOneUpper ?? 'Balenciaga',
            rightTwoUpper: shoesCustomizationStep?.rightTwoUpper ?? '',
          })
          setFieldsVisibility((prevState) => ({
            ...prevState,
            color: true,
            size: false,
            leftShank: false,
            leftOneUpper: false,
            leftTwoUpper: false,
            rightShank: false,
            rightOneUpper: false,
            rightTwoUpper: false,
          }))
        }, 10)

      return Array.from(new Set(articleNames))
    }
  }, [
    event?.shoes,
    shoesCustomizationStep.article,
    shoesCustomizationStep?.leftOneUpper,
    shoesCustomizationStep?.leftShank,
    shoesCustomizationStep?.leftTwoUpper,
    shoesCustomizationStep?.rightOneUpper,
    shoesCustomizationStep?.rightShank,
    shoesCustomizationStep?.rightTwoUpper,
  ])

  const colorOptions = useMemo(() => {
    const articles = event?.shoes?.filter((article) => article.name === selectedArticle)
    const colors = articles?.map((article) => article.color)
    return Array.from(new Set(colors))
  }, [event?.shoes, selectedArticle])

  const sizeOptions = useMemo(() => {
    return event?.shoes
      ?.find((article) => article.name === selectedArticle && article.color === selectedColor)
      ?.sizes?.map(String)
  }, [event?.shoes, selectedArticle, selectedColor])

  console.log(shoesCustomizationStep, articlesOptions.sort())

  // PROPS

  const headerProps = useHeaderProps()

  const templateProps: ShoesCustomizationTemplateProps = useMemo(() => {
    return {
      layoutProps: {
        header: <Header {...headerProps} />,
      },
      title: t('bagCustomization.customizationLabel'),
      form: {
        formik: {
          innerRef: $formikRef,
          validationSchema: Yup.object().shape({}),
          initialValues: {
            article: shoesCustomizationStep?.article ?? '',
            color: shoesCustomizationStep?.color ?? '',
            size: shoesCustomizationStep?.size ?? '',
            leftShank: shoesCustomizationStep?.leftShank ?? '',
            leftOneUpper: shoesCustomizationStep?.leftOneUpper ?? '',
            leftTwoUpper: shoesCustomizationStep?.leftTwoUpper ?? '',
            rightShank: shoesCustomizationStep?.rightShank ?? '',
            rightOneUpper: shoesCustomizationStep?.rightOneUpper ?? 'Balenciaga',
            rightTwoUpper: shoesCustomizationStep?.rightTwoUpper ?? '',
          },
          onSubmit: (values) => {
            dispatch(actions.app.setShoesCustomizationStep(values))
            dispatch(actions.app.setScreen({ screen: Screen.Disclaimer }))
          },
          onContextUpdate: () => {
            setSelectedArticle($formikRef.current?.values?.article)
            setSelectedColor($formikRef.current?.values?.color)
          },
        },
        fields: {
          article: fieldsVisibility.article
            ? {
                name: 'article',
                placeholder: t('shoesCustomization.form.article.placeholder'),
                options:
                  articlesOptions?.map((option) => ({
                    label: option,
                    value: option,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      color: '',
                      size: '',
                      leftShank: '',
                      leftOneUpper: '',
                      leftTwoUpper: '',
                      rightShank: '',
                      rightOneUpper: 'Balenciaga',
                      rightTwoUpper: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      color: true,
                      size: false,
                      leftShank: false,
                      leftOneUpper: false,
                      leftTwoUpper: false,
                      rightShank: false,
                      rightOneUpper: false,
                      rightTwoUpper: false,
                    }))
                  }, 10)
                },
              }
            : undefined,
          color: fieldsVisibility.color
            ? {
                name: 'color',
                placeholder: t('shoesCustomization.form.color.placeholder'),
                options:
                  colorOptions?.map((option) => ({
                    label: option,
                    value: option,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      size: '',
                      leftShank: '',
                      leftOneUpper: '',
                      leftTwoUpper: '',
                      rightShank: '',
                      rightOneUpper: 'Balenciaga',
                      rightTwoUpper: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      size: true,
                      leftShank: false,
                      leftOneUpper: false,
                      leftTwoUpper: false,
                      rightShank: false,
                      rightOneUpper: false,
                      rightTwoUpper: false,
                    }))
                  }, 10)
                },
              }
            : undefined,
          size: fieldsVisibility.size
            ? {
                name: 'size',
                placeholder: t('shoesCustomization.form.size.placeholder'),
                options:
                  sizeOptions?.map((option) => ({
                    label: option,
                    value: option,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      leftShank: '',
                      leftOneUpper: '',
                      leftTwoUpper: '',
                      rightShank: '',
                      rightOneUpper: 'Balenciaga',
                      rightTwoUpper: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      leftShank: true,
                      leftOneUpper: true,
                      leftTwoUpper: true,
                      rightShank: true,
                      rightOneUpper: true,
                      rightTwoUpper: true,
                    }))
                  }, 10)
                },
              }
            : undefined,
          leftShank: fieldsVisibility.leftShank
            ? {
                name: 'leftShank',
                placeholder: t('shoesCustomization.form.leftShank.placeholder'),
                showCounter: true,
                maxLength: 8,
              }
            : undefined,
          leftOneUpper: fieldsVisibility.leftOneUpper
            ? {
                name: 'leftOneUpper',
                placeholder: t('shoesCustomization.form.leftOneUpper.placeholder'),
                showCounter: true,
                maxLength: 11,
              }
            : undefined,
          leftTwoUpper: fieldsVisibility.leftTwoUpper
            ? {
                name: 'leftTwoUpper',
                placeholder: t('shoesCustomization.form.leftTwoUpper.placeholder'),
                showCounter: true,
                maxLength: 11,
              }
            : undefined,
          rightShank: fieldsVisibility.rightShank
            ? {
                name: 'rightShank',
                placeholder: t('shoesCustomization.form.rightShank.placeholder'),
                showCounter: true,
                maxLength: 8,
              }
            : undefined,
          rightOneUpper: fieldsVisibility.rightOneUpper
            ? {
                name: 'rightOneUpper',
                placeholder: t('shoesCustomization.form.rightOneUpper.placeholder'),
              }
            : undefined,
          rightTwoUpper: fieldsVisibility.rightTwoUpper
            ? {
                name: 'rightTwoUpper',
                placeholder: t('shoesCustomization.form.rightTwoUpper.placeholder'),
                showCounter: true,
                maxLength: 11,
              }
            : undefined,
        },
        leftLabel: t('shoesCustomization.leftLabel'),
        rightLabel: t('shoesCustomization.rightLabel'),
        back: {
          label: t('shoesCustomization.back'),
          onClick: () => {
            dispatch(actions.app.setScreen({ screen: Screen.home }))
          },
        },
        submitButton: {
          text: t('shoesCustomization.submitButton'),
        },
      },
      pagination: {
        current: 3,
        total: 4,
      },
    }
  }, [
    articlesOptions,
    colorOptions,
    dispatch,
    fieldsVisibility.article,
    fieldsVisibility.color,
    fieldsVisibility.leftOneUpper,
    fieldsVisibility.leftShank,
    fieldsVisibility.leftTwoUpper,
    fieldsVisibility.rightOneUpper,
    fieldsVisibility.rightShank,
    fieldsVisibility.rightTwoUpper,
    fieldsVisibility.size,
    headerProps,
    shoesCustomizationStep?.article,
    shoesCustomizationStep?.color,
    shoesCustomizationStep?.leftOneUpper,
    shoesCustomizationStep?.leftShank,
    shoesCustomizationStep?.leftTwoUpper,
    shoesCustomizationStep?.rightOneUpper,
    shoesCustomizationStep?.rightShank,
    shoesCustomizationStep?.rightTwoUpper,
    shoesCustomizationStep?.size,
    sizeOptions,
    t,
  ])

  return <ShoesCustomizationTemplate {...templateProps} />
}

// @ts-ignore
export default ShoesCustomizationPage
