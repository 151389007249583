import styled from 'styled-components'

import { textStyles } from '../../../theme'

export const Container = styled.div``

export const ErrorText = styled.p`
  color: red;
  ${textStyles.text}
  text-align: center;
`
