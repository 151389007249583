import gql from 'graphql-tag'

import { AppSubmitOrderMutation } from '../../../types/api'

export default {
  mutation: gql`
    mutation AppSubmitOrder($input: OrderInput!) {
      submitOrder(input: $input) {
        id
      }
    }
  `,
  transformer: (response: AppSubmitOrderMutation) => {
    return response.submitOrder
  },
}
