import React, { FC } from 'react'

import { ActionButtonProps } from '../../components/ActionButton'
import Layout, { LayoutProps } from '../../layouts/Layout'

import * as SC from './styled'

export type ReasonProps = {
  text: string
}

export type NotFoundTemplateProps = {
  layoutPtops?: LayoutProps
  title?: string
  cta?: ActionButtonProps
}

const NotFoundTemplate: FC<NotFoundTemplateProps> = ({ layoutPtops, title, cta }) => {
  return (
    <Layout {...layoutPtops}>
      <SC.Content>
        <SC.Title>{title}</SC.Title>
        <SC.Cta {...cta} />
      </SC.Content>
    </Layout>
  )
}

export default NotFoundTemplate
