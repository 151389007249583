import gql from 'graphql-tag'

import { AppGetStoreQuery } from '../../../types/api'

export default {
  query: gql`
    query AppGetStore {
      stores {
        id
        name
        events {
          id
          name
          type
        }
      }
    }
  `,
  transformer: (response: AppGetStoreQuery) => {
    return response.stores
  },
}
