import styled from 'styled-components'

import TextFieldC from '../../form/TextField'
import FormSubmitErrors from '../../form/FormSubmitErrors'
import SubmitButton from '../../form/SubmitButton'
import FormikForm from '../../form/FormikForm'
import { textStyles } from '../../../theme'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Text = styled.p<{ dangerouslySetInnerHTML: any }>`
  ${textStyles.text};
  margin-top: 25px;
`

export const TextPassword = styled.p`
  ${textStyles.titleH3};
  text-align: center;
  margin-bottom: 25px;
`

export const TextField = styled(TextFieldC)`
  &:not(:first-child) {
    margin-top: 25px;
  }
`

export const ForgotPassword = styled.p`
  ${textStyles.text};
  align-self: flex-end;
  margin-top: 25px;
  cursor: pointer;
`

export const SubmitErrors = styled(FormSubmitErrors)`
  margin-top: 25px;
`

export const Submit = styled(SubmitButton)`
  width: 100%;
  max-width: 250px;
  margin-top: 55px;
`
