import styled, { css } from 'styled-components'

import InputLabel from '../InputLabel'
import { textStyles } from '../../../theme'
import Icon from '../../Icon'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Label = styled(InputLabel)`
  margin-bottom: 4px;
`

export const SelectContainer = styled.div`
  position: relative;
`

export const Select = styled.select<{ $error?: boolean; value?: string; $hasBorder: boolean }>`
  display: block;
  width: 100%;
  max-width: 100%;
  height: 40px;
  padding: 1px 24px 0 12px;
  ${textStyles.fieldLabel};
  line-height: 40px;
  color: #000;
  border-style: ${(props) => (props.$hasBorder ? 'solid' : 'none')};
  border-width: ${(props) => (props.$hasBorder ? '1px' : '0')};
  border-color: #000;
  border-radius: 0;
  box-shadow: none;
  background-color: #fff;
  outline: none;
  appearance: none;
  user-select: all;
  cursor: pointer;

  ${(props) =>
    !props.value &&
    css`
      color: #808080;
    `}
  ${(props) =>
    props.$error &&
    css`
      color: #ff0000;
      border-color: #ff0000;
    `}
  & option[value=""][disabled] {
    display: none;
  }
`

export const SelectArrow = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  color: black;
  pointer-events: none;
`
