import { PayloadAction } from '@reduxjs/toolkit'

import { GraphqlErrors, transformErrors } from './GraphqlHelpers'

export type ServiceValues<Data = any, Params = any> = {
  pending: boolean
  success: boolean
  complete: boolean
  errors: GraphqlErrors | null
  data: Data | null
  params: Params | null
}

export type ServiceRequestAction<T = undefined> = PayloadAction<T>
export type ServiceSuccessAction<Data> = PayloadAction<Data>
export type ServiceErrorAction = PayloadAction<any | undefined>

export const getServiceValues = <Data>(data?: Data): ServiceValues<Data, any> => ({
  pending: false,
  success: false,
  complete: false,
  errors: null,
  data: data ?? null,
  params: null,
})

export const getServiceReducers = <Id extends string, Data, Params>(id: Id) => {
  const requestActionCreator = (state: any, action: ServiceRequestAction<Params>) => {
    const params = action.payload
    state[id] = {
      pending: true,
      success: false,
      complete: false,
      errors: null,
      data: state?.[id]?.data,
      params,
    }
  }

  const successActionCreator = (state: any, action: ServiceSuccessAction<Data>) => {
    const data = action.payload
    state[id] = {
      ...state[id],
      pending: false,
      success: true,
      complete: true,
      errors: null,
      data,
    }
  }

  const errorActionCreator = (state: any, action: ServiceErrorAction) => {
    const errors = transformErrors(action.payload)
    state[id] = {
      ...state[id],
      pending: false,
      success: false,
      complete: true,
      data: null,
      errors,
    }
  }

  const resetActionCreator = (state: any) => {
    state[id] = getServiceValues()
  }

  return {
    [`${id}Request`]: requestActionCreator,
    [`${id}Success`]: successActionCreator,
    [`${id}Error`]: errorActionCreator,
    [`${id}Reset`]: resetActionCreator,
  } as Record<`${typeof id}Request`, typeof requestActionCreator> &
    Record<`${typeof id}Success`, typeof successActionCreator> &
    Record<`${typeof id}Error`, typeof errorActionCreator> &
    Record<`${typeof id}Reset`, typeof resetActionCreator>
}

export const getService = <Id extends string, Data = null, Params = null>(
  id: Id,
  data?: Data | null
) => ({
  values: getServiceValues(data),
  reducers: getServiceReducers<Id, Data, Params>(id),
})
