import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../store'
import { THEMES } from '../../constants'
import { getService } from '../../helpers/ReduxHelpers'
import { ApiResponse } from '../api/types/state'
import { services } from '../../graphql'
import {
  AppCreateClientMutationVariables,
  AppGetEventQueryVariables,
  AppSubmitOrderMutationVariables,
  SaleType,
  StoresByLocationsQueryVariables,
} from '../../graphql/types/api'
import { Screen } from '../../types/global'

import { actionTypes } from './types'

// Services

const createClientService = getService<
  'createClient',
  ApiResponse<typeof services.app.mutations.createClient>['data'],
  AppCreateClientMutationVariables
>('createClient')

const getEventService = getService<
  'getEvent',
  ApiResponse<typeof services.app.queries.getEvent>['data'],
  AppGetEventQueryVariables
>('getEvent')

const getStoresByLocationsService = getService<
  'getStoresByLocations',
  ApiResponse<typeof services.app.queries.getStoresByLocations>['data'],
  StoresByLocationsQueryVariables
>('getStoresByLocations')

const submitOrderService = getService<
  'submitOrder',
  ApiResponse<typeof services.app.mutations.submitOrder>['data'],
  AppSubmitOrderMutationVariables
>('submitOrder')

//
// Initial state
//

export type AppState = {
  isInit: boolean
  screen: Screen
  theme: string
  stores: ApiResponse<typeof services.app.queries.getStores>['data']
  zones: ApiResponse<typeof services.app.queries.getZones>['data']
  getEvent: typeof getEventService.values
  getStoresByLocations: typeof getStoresByLocationsService.values
  disclaimers: ApiResponse<typeof services.app.queries.getDisclaimers>['data']
  storeAndEventStep: {
    zone: string | null
    country: string | null
    storeId: string | null
    eventId: string | null
    saleType: SaleType | null
  }
  shoesCustomizationStep: {
    article?: string
    color?: string
    size?: string
    leftShank?: string
    leftOneUpper?: string
    leftTwoUpper?: string
    rightShank?: string
    rightOneUpper?: string
    rightTwoUpper?: string
  }
  bagCustomizationStep: {
    line?: string
    article?: string
    color?: string
    customizationType?: string
    customizationColor?: string
    fontSize?: string
    customizationMessageType?: string
    customizationMessage?: string
  }
  createClient: typeof createClientService.values
  submitOrder: typeof submitOrderService.values
}

const initialState: AppState = {
  isInit: false,
  theme: THEMES.DEFAULT,
  screen: Screen.home,
  stores: null,
  zones: null,
  getEvent: getEventService.values,
  getStoresByLocations: getStoresByLocationsService.values,
  disclaimers: null,
  storeAndEventStep: {
    zone: null,
    country: null,
    storeId: null,
    eventId: null,
    saleType: null,
  },
  shoesCustomizationStep: {},
  bagCustomizationStep: {},
  createClient: createClientService.values,
  submitOrder: submitOrderService.values,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init: () => undefined,
    resetSteps: (state) => {
      state.storeAndEventStep = initialState.storeAndEventStep
      state.shoesCustomizationStep = initialState.shoesCustomizationStep
      state.bagCustomizationStep = initialState.bagCustomizationStep
      state.createClient = initialState.createClient
      state.submitOrder = initialState.submitOrder
    },
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload
      state.isInit = isInit
    },
    setScreen: (state, { payload }: actionTypes.setScreen) => {
      state.screen = payload.screen
    },
    setStores: (state, { payload }: actionTypes.setStores) => {
      state.stores = payload
    },
    setZones: (state, { payload }: actionTypes.setZones) => {
      state.zones = payload
    },
    setDisclaimers: (state, { payload }: actionTypes.setDisclaimers) => {
      state.disclaimers = payload
    },
    setStoreAndEventStep: (state, { payload }: actionTypes.setStoreAndEventStep) => {
      state.storeAndEventStep = payload
    },
    setShoesCustomizationStep: (state, { payload }: actionTypes.setShoesCustomizationStep) => {
      state.shoesCustomizationStep = payload
    },
    setBagCustomizationStep: (state, { payload }: actionTypes.setBagCustomizationStep) => {
      state.bagCustomizationStep = payload
    },
    ...createClientService.reducers,
    ...getEventService.reducers,
    ...getStoresByLocationsService.reducers,
    ...submitOrderService.reducers,
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const isInit = (state: RootState) => root(state).isInit
const screen = (state: RootState) => root(state).screen
const theme = (state: RootState) => root(state).theme
const stores = (state: RootState) => root(state).stores
const zones = (state: RootState) => root(state).zones
const getEvent = (state: RootState) => root(state).getEvent
const getStoresByLocations = (state: RootState) => root(state).getStoresByLocations
const disclaimers = (state: RootState) => root(state).disclaimers
const storeAndEventStep = (state: RootState) => root(state).storeAndEventStep
const shoesCustomizationStep = (state: RootState) => root(state).shoesCustomizationStep
const bagCustomizationStep = (state: RootState) => root(state).bagCustomizationStep
const createClient = (state: RootState) => root(state).createClient
const submitOrder = (state: RootState) => root(state).submitOrder

export const selectors = {
  isInit,
  theme,
  screen,
  stores,
  zones,
  getEvent,
  getStoresByLocations,
  disclaimers,
  storeAndEventStep,
  shoesCustomizationStep,
  bagCustomizationStep,
  createClient,
  submitOrder,
}
