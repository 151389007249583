import React, { FC } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type ActionButtonProps = {
  className?: string
  link?: LinkProps
  type?: 'submit' | 'button'
  text?: string
  variant?: 'text' | 'outlined' | 'contained'
  icon?: Icons
  iconColor?: string
  iconSide?: 'left' | 'right'
  disabled?: boolean
  outlined?: boolean
  onClick?: (e: any | undefined) => void
}

const ActionButton: FC<ActionButtonProps> = (props) => {
  const {
    className,
    link,
    children,
    onClick,
    type = 'button',
    text,
    icon,
    iconColor,
    iconSide,
    disabled,
    outlined,
  } = props

  const renderIcon = () =>
    icon && <SC.IconIn iconSide={iconSide} icon={Icons[icon]} color={iconColor ? iconColor : ''} />

  const renderButton = () => (
    <SC.Button
      className={!(link?.href || link?.link) ? className : ''}
      onClick={onClick}
      type={type}
      disabled={disabled}
      $outlined={outlined}
    >
      {icon && (!iconSide || iconSide === 'left') && renderIcon()}
      {children || text}
      {icon && iconSide === 'right' && renderIcon()}
    </SC.Button>
  )

  return link?.href || link?.link ? (
    <SC.Link className={className} {...link} href={link?.href || link?.link} onClick={onClick}>
      {renderButton()}
    </SC.Link>
  ) : (
    renderButton()
  )
}

export default ActionButton
