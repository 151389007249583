import React from 'react'

import { FormSubmitErrorsProps } from '../../form/FormSubmitErrors'
import FormikField from '../../form/FormikField'
import { SubmitButtonProps } from '../../form/SubmitButton'
import { FormikFormProps } from '../../form/FormikForm'
import { SelectProps } from '../../form/Select'

import * as SC from './styled'

export type StoreAndEventFormValues = {
  zone: string
  country: string
  store: string
  event: string
  saleType: string
}

export type StoreAndEventFormProps = {
  className?: string
  formik: FormikFormProps<StoreAndEventFormValues>
  fields: {
    zone?: SelectProps
    country?: SelectProps
    store?: SelectProps
    event?: SelectProps
    saleType?: SelectProps
  }
  back?: {
    label: string
    onClick?: () => void
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: SubmitButtonProps
}

const StoreAndEventForm: React.FC<StoreAndEventFormProps> = ({
  className,
  formik,
  fields,
  submitErrors,
  back,
  submitButton,
}) => {
  return (
    <SC.Form {...formik} className={className}>
      {fields.zone && <FormikField {...fields.zone} Component={SC.SelectField} />}
      {fields.country && <FormikField {...fields.country} Component={SC.SelectField} />}
      {fields.store && <FormikField {...fields.store} Component={SC.SelectField} />}
      {fields.event && <FormikField {...fields.event} Component={SC.SelectField} />}
      {fields.saleType && <FormikField {...fields.saleType} Component={SC.SelectField} />}

      {submitErrors && <SC.SubmitErrors errors={submitErrors} />}
      <SC.Buttons>
        {back && <SC.Back onClick={back.onClick}>{back.label}</SC.Back>}
        <SC.Submit {...submitButton} />
      </SC.Buttons>
    </SC.Form>
  )
}

export default StoreAndEventForm
