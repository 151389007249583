import styled from 'styled-components'

import Icon from '../Icon'
import Select from '../form/Select'

export const Container = styled.header`
  width: 100%;
  background: white;
  border-bottom: 1px solid black;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`

export const LogoIcon = styled(Icon)`
  width: 17rem;
  cursor: pointer;
`

export const LanguageSelector = styled(Select)`
  width: 130px;
  select {
    font-size: 12px;
    text-transform: uppercase;
  }
`
