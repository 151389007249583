import React from 'react'

import Header from '../../components/Header'

import { NotFoundTemplateProps } from '.'

export const notFoundMock: NotFoundTemplateProps = {
  layoutPtops: {
    header: <Header />,
  },
  title: 'Page not found',
  cta: {
    text: 'Back to home',
    link: {
      link: '/',
    },
  },
}
