import React, { FC } from 'react'

import Layout, { LayoutProps } from '../../layouts/Layout'
import { LoginFormProps } from '../../components/forms/LoginForm'

import * as SC from './styled'

export type LoginTemplateProps = {
  className?: string
  layoutProps?: LayoutProps
  title?: string
  form: LoginFormProps
}

const LoginTemplate: FC<LoginTemplateProps> = (props) => {
  const { title, layoutProps, form } = props

  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <SC.Box>
          <SC.Title>{title}</SC.Title>
          <SC.Form {...form} />
        </SC.Box>
      </SC.Content>
    </Layout>
  )
}

export default LoginTemplate
