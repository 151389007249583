import styled from 'styled-components'

import { textStyles } from '../../theme'
import LoginForm from '../../components/forms/LoginForm'
import ResetForm from '../../components/forms/ResetForm'
import SubmitButton from '../../components/form/SubmitButton'

export const Content = styled.div`
  padding: 4rem 0;
  flex-grow: 1;
`

export const Box = styled.div`
  max-width: 335px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  ${textStyles.titleH1};
  text-align: center;
`

export const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const SuccessMessage = styled.p`
  ${textStyles.text};
  text-align: center;
`

export const Form = styled(ResetForm)`
  margin-top: 55px;
  width: 100%;
`

export const BackButton = styled(SubmitButton)`
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  margin-top: 55px;
`
