import gql from 'graphql-tag'

import { AppCreateClientMutation } from '../../../types/api'

export default {
  mutation: gql`
    mutation AppCreateClient($input: ClientInput!) {
      createClient(input: $input) {
        id
        first_name
        last_name
        email
        address
        city
        zipcode
      }
    }
  `,
  transformer: (response: AppCreateClientMutation) => {
    return response.createClient
  },
}
