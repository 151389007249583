import React, { Fragment } from 'react'

import * as SC from './styled'

export type ErrorType = {
  label?: string
}

export type FormSubmitErrorsProps = {
  className?: string
  errors?: ErrorType[] | null
}

const FormSubmitErrors: React.FC<FormSubmitErrorsProps> = ({ className, errors }) => {
  if (!errors || !(errors?.length > 0)) return null

  return (
    <SC.Container className={className}>
      {errors?.map(
        (error, key) =>
          error.label && (
            <Fragment key={error.label}>
              <SC.ErrorText>{error.label}</SC.ErrorText>
            </Fragment>
          )
      )}
    </SC.Container>
  )
}

export default FormSubmitErrors
