import React, { useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import BagCustomizationTemplate, {
  BagCustomizationTemplateProps,
} from '../../templates/BagCustomization'
import { useHeaderProps } from '../../components/Header/useProps'
import Header from '../../components/Header'
import { BagCustomizationFormValues } from '../../components/forms/BagCustomizationForm'
import Select from '../../components/form/Select'
import { actions, selectors } from '../../redux'
import { EventType, MessageType } from '../../graphql/types/api'
import TextField from '../../components/form/TextField'
import { Screen } from '../../types/global'

const BagCustomizationPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // SELECTORS

  const getEvent = useSelector(selectors.app.getEvent)
  const event = useMemo(() => getEvent.data, [getEvent.data])
  const bagCustomizationStep = useSelector(selectors.app.bagCustomizationStep)

  // REF

  const $formikRef = useRef<FormikProps<BagCustomizationFormValues>>(null)

  // STATE

  const [fieldsVisibility, setFieldsVisibility] = useState({
    line: true,
    article: !!bagCustomizationStep.article,
    color: !!bagCustomizationStep.color,
    customizationType:
      (event?.coatings?.length ?? 0) > 0 && !!bagCustomizationStep.customizationType,
    customizationColor:
      (event?.colors?.length ?? 0) > 0 && !!bagCustomizationStep.customizationColor,
    fontSize: (event?.sizes?.length ?? 0) > 0 && !!bagCustomizationStep.fontSize,
    customizationMessageType: !!bagCustomizationStep.customizationMessageType,
    customizationMessage: !!bagCustomizationStep.customizationMessage,
  })

  const [selectedLineId, setSelectedLineId] = useState(bagCustomizationStep.line)
  const [selectedArticleName, setSelectedArticleName] = useState(bagCustomizationStep.article)
  const [selectedMessageType, setSelectedMessageType] = useState(
    bagCustomizationStep.customizationMessageType
  )

  // MEMO

  const selectedLine = useMemo(() => event?.lines?.find((line) => line.id === selectedLineId), [
    event?.lines,
    selectedLineId,
  ])

  const selectedLineArticles = useMemo(() => {
    switch (event?.type) {
      case EventType.Rtw:
        return selectedLine?.readyToWears
      case EventType.Items:
        return selectedLine?.items
      case EventType.Bags:
      default:
        return selectedLine?.bags
    }
  }, [event, selectedLine])

  const articlesOptions = useMemo(() => {
    const articleNames = selectedLineArticles?.map((article) => article.name)
    return Array.from(new Set(articleNames))
  }, [selectedLineArticles])

  const colorOptions: string[] = useMemo(() => {
    // @ts-ignore
    const articles = selectedLineArticles?.filter((article) => article.name === selectedArticleName)
    const colors = articles?.map((article: any) => article.color)
    return Array.from(new Set(colors))
  }, [selectedArticleName, selectedLineArticles])

  const lines = useMemo(() => {
    const eventLine = event?.lines

    if (eventLine?.find((elem) => elem.id === bagCustomizationStep.line)) {
      return eventLine
    } else {
      setTimeout(() => {
        $formikRef.current?.setValues({
          line: '',
          article: '',
          color: '',
          customizationType: '',
          customizationColor: '',
          fontSize: '',
          customizationMessageType: '',
          customizationMessage: '',
        })
        setFieldsVisibility((prevState) => ({
          ...prevState,
          article: true,
          color: false,
          customizationType: false,
          customizationColor: false,
          fontSize: false,
          customizationMessageType: false,
          customizationMessage: false,
        }))
      }, 10)
      return event?.lines
    }
  }, [bagCustomizationStep.line, event?.lines])

  // PROPS

  const headerProps = useHeaderProps()

  const isSubmitEnabled = !!$formikRef.current?.values.color || bagCustomizationStep.color

  const templateProps: BagCustomizationTemplateProps = useMemo(() => {
    return {
      layoutProps: {
        header: <Header {...headerProps} />,
      },
      title: t('bagCustomization.customizationLabel'),
      form: {
        formik: {
          innerRef: $formikRef,
          validationSchema: Yup.object().shape({
            line: Yup.string().required(),
            article: Yup.string().required(),
            color: Yup.string().required(),
            customizationType:
              (event?.coatings?.length ?? 0) > 0 ? Yup.string().required() : Yup.string(),
            customizationColor:
              (event?.colors?.length ?? 0) > 0 ? Yup.string().required() : Yup.string(),
            fontSize: (event?.sizes?.length ?? 0) > 0 ? Yup.string().required() : Yup.string(),
            customizationMessageType: Yup.string().required(),
            customizationMessage: Yup.string().required(),
          }),
          initialValues: {
            line: bagCustomizationStep.line ?? '',
            article: bagCustomizationStep.article ?? '',
            color: bagCustomizationStep.color ?? '',
            customizationType: bagCustomizationStep.customizationType ?? '',
            customizationColor: bagCustomizationStep.customizationColor ?? '',
            fontSize: bagCustomizationStep.fontSize ?? '',
            customizationMessageType: bagCustomizationStep.customizationMessageType ?? '',
            customizationMessage: bagCustomizationStep.customizationMessage ?? '',
          },
          onSubmit: (values) => {
            console.log(values)
            dispatch(actions.app.setBagCustomizationStep(values))
            dispatch(actions.app.setScreen({ screen: Screen.Disclaimer }))
          },
          onContextUpdate: () => {
            setSelectedLineId($formikRef.current?.values?.line)
            setSelectedArticleName($formikRef.current?.values?.article)
            setSelectedMessageType($formikRef.current?.values?.customizationMessageType)
          },
        },
        fields: {
          line: fieldsVisibility.line
            ? {
                name: 'line',
                placeholder: t('bagCustomization.form.line.placeholder'),
                options:
                  lines?.map((line) => ({
                    label: line.name,
                    value: line.id,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      article: '',
                      color: '',
                      customizationType: '',
                      customizationColor: '',
                      fontSize: '',
                      customizationMessageType: '',
                      customizationMessage: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      article: true,
                      color: false,
                      customizationType: false,
                      customizationColor: false,
                      fontSize: false,
                      customizationMessageType: false,
                      customizationMessage: false,
                    }))
                  }, 50)
                },
              }
            : undefined,
          article: fieldsVisibility.article
            ? {
                name: 'article',
                placeholder: t('bagCustomization.form.article.placeholder'),
                options:
                  articlesOptions?.map((option) => ({
                    label: option,
                    value: option,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      color: '',
                      customizationType: '',
                      customizationColor: '',
                      fontSize: '',
                      customizationMessageType: '',
                      customizationMessage: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      color: true,
                      customizationType: false,
                      customizationColor: false,
                      fontSize: false,
                      customizationMessageType: false,
                      customizationMessage: false,
                    }))
                  }, 50)
                },
              }
            : undefined,
          color: fieldsVisibility.color
            ? {
                name: 'color',
                placeholder: t('bagCustomization.form.color.placeholder'),
                options:
                  colorOptions?.map((option) => ({
                    label: option,
                    value: option,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      customizationType: '',
                      customizationColor: '',
                      fontSize: '',
                      customizationMessageType: '',
                      customizationMessage: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      customizationType: (event?.coatings?.length ?? 0) > 0,
                      customizationColor: (event?.colors?.length ?? 0) > 0,
                      fontSize: (event?.sizes?.length ?? 0) > 0,
                      customizationMessageType: true,
                      customizationMessage: false,
                    }))
                  }, 50)
                },
              }
            : undefined,
          customizationType: fieldsVisibility.customizationType
            ? {
                name: 'customizationType',
                placeholder: t('bagCustomization.form.customizationType.placeholder'),
                options:
                  event?.coatings?.map((coating) => ({
                    label: coating,
                    value: coating,
                  })) ?? [],
              }
            : undefined,
          customizationColor: fieldsVisibility.customizationColor
            ? {
                name: 'customizationColor',
                placeholder: t('bagCustomization.form.customizationColor.placeholder'),
                options:
                  event?.colors?.map((color) => ({
                    label: color,
                    value: color,
                  })) ?? [],
              }
            : undefined,
          fontSize: fieldsVisibility.fontSize
            ? {
                name: 'fontSize',
                placeholder: t('bagCustomization.form.fontSize.placeholder'),
                options:
                  event?.sizes?.map((size) => ({
                    label: size,
                    value: size,
                  })) ?? [],
              }
            : undefined,
          customizationMessageType: fieldsVisibility.customizationMessageType
            ? {
                name: 'customizationMessageType',
                placeholder: t('bagCustomization.form.customizationMessageType.placeholder'),
                options:
                  event?.messageTypes?.map((messageType: string) => ({
                    label: t(`enum.messageType.${messageType}` as any),
                    value: messageType,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      customizationMessage: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      customizationMessage: true,
                    }))
                  }, 50)
                },
              }
            : undefined,
          customizationMessage: fieldsVisibility.customizationMessage
            ? {
                component: selectedMessageType === MessageType.Preset ? Select : TextField,
                props: {
                  name: 'customizationMessage',
                  placeholder: t(
                    `bagCustomization.form.message.${selectedMessageType}.placeholder` as any
                  ),
                  showCounter:
                    selectedMessageType === MessageType.InitialsWithDots ||
                    selectedMessageType === MessageType.Initials ||
                    selectedMessageType === MessageType.Custom,
                  maxLength:
                    selectedMessageType === MessageType.Initials ||
                    selectedMessageType === MessageType.InitialsWithDots
                      ? 3
                      : 10,
                  options:
                    selectedMessageType === MessageType.Preset
                      ? event?.presetMessages?.map((presetMessage) => ({
                          label: presetMessage,
                          value: presetMessage,
                        })) ?? []
                      : undefined,
                },
              }
            : undefined,
        },
        customizationLabel: t('bagCustomization.customizationLabel'),
        back: {
          label: t('bagCustomization.back'),
          onClick: () => {
            dispatch(actions.app.setScreen({ screen: Screen.store }))
          },
        },
        submitButton: {
          text: t('bagCustomization.submitButton'),
          onClick:
            selectedMessageType === MessageType.InitialsWithDots
              ? () => {
                  $formikRef.current?.setValues({
                    ...$formikRef.current.values,
                    customizationMessage: $formikRef.current.values.customizationMessage
                      .split('')
                      .join('.'),
                  })
                }
              : undefined,
          disabled: !isSubmitEnabled,
        },
      },
      pagination: {
        current: 3,
        total: 4,
      },
    }
  }, [
    articlesOptions,
    bagCustomizationStep.article,
    bagCustomizationStep.color,
    bagCustomizationStep.customizationColor,
    bagCustomizationStep.customizationMessage,
    bagCustomizationStep.customizationMessageType,
    bagCustomizationStep.customizationType,
    bagCustomizationStep.fontSize,
    bagCustomizationStep.line,
    colorOptions,
    dispatch,
    event?.coatings,
    event?.colors,
    event?.messageTypes,
    event?.presetMessages,
    event?.sizes,
    fieldsVisibility.article,
    fieldsVisibility.color,
    fieldsVisibility.customizationColor,
    fieldsVisibility.customizationMessage,
    fieldsVisibility.customizationMessageType,
    fieldsVisibility.customizationType,
    fieldsVisibility.fontSize,
    fieldsVisibility.line,
    headerProps,
    isSubmitEnabled,
    lines,
    selectedMessageType,
    t,
  ])

  // TODO: redirect to home if no data in store

  return <BagCustomizationTemplate {...templateProps} />
}

export default BagCustomizationPage
