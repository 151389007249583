import React from 'react'

import { ActionButtonProps } from '../../ActionButton'

import * as SC from './styled'

export type SubmitButtonProps = ActionButtonProps & {
  pendingText?: string
  successText?: string
  disabledText?: string
  isPending?: boolean
  isSuccess?: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  className,
  isPending,
  isSuccess,
  disabled,
  successText,
  pendingText,
  disabledText,
  text,
  children,
  ...rest
}) => {
  const displayText =
    disabled && disabledText
      ? disabledText
      : isSuccess && successText
      ? successText
      : isPending && pendingText
      ? pendingText
      : text

  const enabled = !disabled && !isPending

  return (
    <SC.Button
      className={className}
      type="submit"
      $isPending={isPending}
      $isSuccess={isSuccess}
      disabled={!enabled}
      {...rest}
    >
      {children || displayText}
    </SC.Button>
  )
}
export default SubmitButton
