import styled from 'styled-components'

import { textStyles } from '../../theme'
import Icon from '../Icon'

export const Container = styled.div`
  width: 100%;
  text-align: center;
`

export const CanvasContainer = styled.div`
  position: relative;
  width: 100%;
  line-height: 0;
`

export const IndicationText = styled.p`
  position: absolute;
  left: 2rem;
  bottom: 1.5rem;
  pointer-events: none;
  ${textStyles.text};
  line-height: normal;
  letter-spacing: 0.05em;
  color: #808080;
`

export const RefreshIcon = styled(Icon)`
  margin-top: 25px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`
