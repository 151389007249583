import gql from 'graphql-tag'

import { ForgotPasswordInput, ForgotPasswordMutation } from '../../../types/api'

export default {
  mutation: gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
      forgotPassword(input: $input)
    }
  `,
  transformer: (response: ForgotPasswordMutation) => {
    return response
  },
}
