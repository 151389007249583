import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { actions, selectors } from '../../redux'
import { resources } from '../../i18n'
import { router, routesPath } from '../../router'
import { Screen } from '../../types/global'

import { HeaderProps } from '.'

export const useHeaderProps = (): HeaderProps => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  const routerParams = useParams<{ locale: string }>()

  const history = useHistory()

  const isConnected = useSelector(selectors.auth.isConnected)

  return useMemo(
    () => ({
      languageSelect: {
        name: 'language',
        value: routerParams.locale,
        options: Object.keys(resources).map((locale) => ({
          label: resources[locale as keyof typeof resources]?.translation?.locale || locale,
          value: locale,
        })),
        onChange: (e) => {
          console.log(e.target.value)
          const newUrl = `http://${window.location.host}/${e.target.value}`

          window.location.assign(newUrl)
        },
      },
      onLogoClick: () => {
        if (isConnected) {
          dispatch(actions.app.setScreen({ screen: Screen.home }))
        } else {
          history.push(routesPath.root)
        }
      },
    }),
    [i18n]
  )
}
