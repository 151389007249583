import React, { FC } from 'react'

import Layout, { LayoutProps } from '../../layouts/Layout'
import { ResetFormProps } from '../../components/forms/ResetForm'

import * as SC from './styled'

export type ResetTemplateProps = {
  className?: string
  layoutProps?: LayoutProps
  title?: string
  successMessage?: string
  form: ResetFormProps
}

const ResetTemplate: FC<ResetTemplateProps> = (props) => {
  const { title, successMessage, layoutProps, form } = props
  return (
    <Layout {...layoutProps}>
      <SC.Content>
        <SC.Box>
          {form.submitButton.isSuccess ? (
            <SC.SuccessMessageContainer>
              <SC.SuccessMessage>{successMessage}</SC.SuccessMessage>
              <SC.BackButton {...form.backButton} />
            </SC.SuccessMessageContainer>
          ) : (
            <>
              <SC.Title>{title}</SC.Title>
              <SC.Form {...form} />
            </>
          )}
        </SC.Box>
      </SC.Content>
    </Layout>
  )
}

export default ResetTemplate
