import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import fr from './fr.json'
import zh_cn from './zh_cn.json'
import jp from './jp.json'
import zh_tw from './zh_tw.json'

export const resources = {
  en,
  fr,
  zh_cn,
  zh_tw,
  jp,
} as const

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    format: (value, format) => {
      if (format === 'uppercase') {
        return value.toUpperCase()
      }
      return value
    },
  },
})

export default i18n
