import React from 'react'

import NotFoundTemplate from '../templates/NotFound'
import { notFoundMock } from '../templates/NotFound/mocks'
import useNotFound from '../hooks/useNotFound'

function Page404() {
  const notFoundProps = useNotFound()

  return <NotFoundTemplate {...notFoundMock} {...notFoundProps} />
}

export default Page404
