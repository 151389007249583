import gql from 'graphql-tag'

import { AuthLogoutMutation } from '../../../types/api'

export default {
  mutation: gql`
    mutation AuthLogout {
      logout
    }
  `,
  transformer: (response: AuthLogoutMutation) => {
    return response.logout
  },
}
