import React, { useEffect, useMemo, useState, useRef } from 'react'
import { RouteComponentProps, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import LoginTemplate, { LoginTemplateProps } from '../../templates/Login'
import { useHeaderProps } from '../../components/Header/useProps'
import Header from '../../components/Header'
import { actions, selectors } from '../../redux'
import i18n from '../../i18n'
import { LoginFormValues } from '../../components/forms/LoginForm'

const LoginPage: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loginService = useSelector(selectors.auth.login)

  const forgetPasswordService = useSelector(selectors.auth.forgetPassword)

  const headerProps = useHeaderProps()

  const $formikRef = useRef<FormikProps<LoginFormValues>>(null)

  const routerParams = useParams<{ locale: string }>()

  const [languageSet, setLanguageSet] = useState(false)

  const [passwordRecovery, setPasswordRecovery] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(routerParams.locale).then(() => {
      setLanguageSet(true)
    })
  }, [routerParams.locale])

  const templateProps: LoginTemplateProps = useMemo(
    () => ({
      layoutProps: {
        header: <Header {...headerProps} />,
      },
      title: t('login.title'),
      form: {
        passwordRecovery,
        formik: {
          innerRef: $formikRef,
          validationSchema: Yup.object().shape({
            email: Yup.string().required().email(),
            password: passwordRecovery ? Yup.string() : Yup.string().required(),
          }),
          initialValues: {
            email: '',
            password: '',
          },
          onSubmit: (values) => {
            passwordRecovery
              ? dispatch(
                  actions.auth.forgetPasswordRequest({
                    input: {
                      email: $formikRef.current!.values.email!,
                      language: routerParams.locale,
                    },
                  })
                )
              : dispatch(
                  actions.auth.loginRequest({
                    email: values.email,
                    password: values.password,
                  })
                )
          },
        },
        text: t('login.text'),
        fields: {
          email: {
            name: 'email',
            placeholder: t('login.form.email.placeholder'),
            required: true,
          },
          password: {
            name: 'password',
            placeholder: t('login.form.password.placeholder'),
            required: true,
          },
        },
        forgotPassword: {
          text: t('login.forgotPassword'),
          title: t('forgotPassword.title'),
          desc: t('forgotPassword.text'),
          button: t('forgotPassword.submitButton'),
          successMessage: t('forgotPassword.successMessage'),
          mailSent: forgetPasswordService.success,
          onClick: () => {
            setPasswordRecovery((prevState) => !prevState)
          },
        },
        submitErrors: loginService.errors?.length ? [{ label: t('login.error') }] : undefined,
        submitButton: {
          isPending: loginService.pending,
          isSuccess: loginService.success,
          text: t('login.submitButton'),
        },
      },
    }),
    [
      dispatch,
      forgetPasswordService.success,
      headerProps,
      loginService.errors?.length,
      loginService.pending,
      loginService.success,
      passwordRecovery,
      routerParams.locale,
      t,
    ]
  )

  return <>{languageSet && <LoginTemplate {...templateProps} />}</>
}

export default LoginPage
