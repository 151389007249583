import { createGlobalStyle } from 'styled-components'

import { fontsFaces } from './fonts'

const GlobalStyles = createGlobalStyle`
  ${fontsFaces}
  * {
    box-sizing: border-box;
  }
  
  html {
    font-size: 62.5%;
  }
  
  html,
  body,
  #root {
    margin: 0;
    height: 100%;
  }
  
  body {
    background: white;
    color: black
  }
  
  h1, h2, h3, h4, h5, p {
    margin: 0;
  }
`
export default GlobalStyles
