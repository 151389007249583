import React, { FC, ReactNode } from 'react'

import * as SC from './styled'

export type InputLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  className?: string
  required?: boolean
  error?: boolean
  children?: ReactNode
}

const InputLabel: FC<InputLabelProps> = ({ error, ...props }) => {
  return <SC.Label {...props} $error={error} />
}

export default InputLabel
