import { all, call, put, SagaReturnType, select, takeLeading } from 'redux-saga/effects'

import { actions, selectors } from '..'
// eslint-disable-next-line import/no-cycle
import AuthSagas from '../auth/sagas'
import { ApiResponse } from '../api/types/state'
import { services } from '../../graphql'
import ApiSagas from '../api/sagas'
import {
  AppCreateClientMutationVariables,
  AppGetEventQueryVariables,
  AppSubmitOrderMutationVariables,
  StoresByLocationsQueryVariables,
} from '../../graphql/types/api'

export default class AppSagas {
  static *init() {
    const isInit: SagaReturnType<typeof selectors.app.isInit> = yield select(selectors.app.isInit)
    yield call(AuthSagas.onInit) // must be first auth call to refresh token if needed
    yield call(AppSagas.loadInitialData)

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *loadInitialData() {
    yield all([call(AppSagas.loadAuthData)])
  }

  static *loadAuthData() {
    const isConnected: ReturnType<typeof selectors.auth.isConnected> = yield select(
      selectors.auth.isConnected
    )

    if (isConnected) {
      yield all([call(AppSagas.getStores)])
      yield all([call(AppSagas.getZones)])
      yield all([call(AppSagas.getDisclaimers)])
    }
  }

  static *getStores() {
    const response: ApiResponse<typeof services.app.queries.getStores> = yield call(
      ApiSagas.query,
      services.app.queries.getStores
    )

    if (response.data) {
      yield put(actions.app.setStores(response.data))
    }
  }
  static *getStoresByLocations({
    payload,
  }: ReturnType<typeof actions.app.getStoresByLocationsRequest>) {
    const response: ApiResponse<typeof services.app.queries.getStoresByLocations> = yield call(
      ApiSagas.query,
      services.app.queries.getStoresByLocations,
      {
        ...payload,
      } as StoresByLocationsQueryVariables
    )

    if (response.errors) {
      yield put(actions.app.getStoresByLocationsError(response.errors))
    }

    if (response.data) {
      yield put(actions.app.getStoresByLocationsSuccess(response.data))
    }
  }
  static *getZones() {
    const response: ApiResponse<typeof services.app.queries.getZones> = yield call(
      ApiSagas.query,
      services.app.queries.getZones
    )

    if (response.data) {
      yield put(actions.app.setZones(response.data))
    }
  }

  static *getDisclaimers() {
    const response: ApiResponse<typeof services.app.queries.getDisclaimers> = yield call(
      ApiSagas.query,
      services.app.queries.getDisclaimers
    )

    console.log(response)

    if (response.data) {
      yield put(actions.app.setDisclaimers(response.data))
    }
  }

  static *getEvent({ payload }: ReturnType<typeof actions.app.getEventRequest>) {
    const response: ApiResponse<typeof services.app.queries.getEvent> = yield call(
      ApiSagas.query,
      services.app.queries.getEvent,
      {
        ...payload,
      } as AppGetEventQueryVariables
    )

    if (response.errors) {
      yield put(actions.app.getEventError(response.errors))
    }

    if (response.data) {
      yield put(actions.app.getEventSuccess(response.data))
    }
  }

  static *createClient({ payload }: ReturnType<typeof actions.app.createClientRequest>) {
    const response: ApiResponse<typeof services.app.mutations.createClient> = yield call(
      ApiSagas.mutate,
      services.app.mutations.createClient,
      {
        input: payload.input,
      } as AppCreateClientMutationVariables
    )

    if (response.errors) {
      yield put(actions.app.createClientError(response.errors))
    }

    if (response.data) {
      yield put(actions.app.createClientSuccess(response.data))
    }
  }

  static *submitOrderRequest({ payload }: ReturnType<typeof actions.app.submitOrderRequest>) {
    const response: ApiResponse<typeof services.app.mutations.submitOrder> = yield call(
      ApiSagas.mutate,
      services.app.mutations.submitOrder,
      {
        input: payload.input,
      } as AppSubmitOrderMutationVariables
    )

    if (response.errors) {
      yield put(actions.app.submitOrderError(response.errors))
    }

    if (response.data) {
      yield put(actions.app.submitOrderSuccess(response.data))
    }
  }

  static *loop() {
    yield all([
      takeLeading('persist/REHYDRATE', this.init),
      takeLeading(actions.app.createClientRequest, this.createClient),
      takeLeading(actions.app.getEventRequest, this.getEvent),
      takeLeading(actions.app.getStoresByLocationsRequest, this.getStoresByLocations),
      takeLeading(actions.app.submitOrderRequest, this.submitOrderRequest),
    ])
  }
}
