import React from 'react'

import { FormSubmitErrorsProps } from '../../form/FormSubmitErrors'
import { TextFieldProps } from '../../form/TextField'
import FormikField from '../../form/FormikField'
import { SubmitButtonProps } from '../../form/SubmitButton'
import { FormikFormProps } from '../../form/FormikForm'

import * as SC from './styled'

export type LoginFormValues = {
  email: string
  password: string
}

export type LoginFormProps = {
  className?: string
  formik: FormikFormProps<LoginFormValues>
  text?: string
  passwordRecovery?: boolean
  fields: {
    email: TextFieldProps
    password: TextFieldProps
  }
  forgotPassword: {
    text: string
    title: string
    desc: string
    button: string
    onClick?: () => void
    successMessage: string
    mailSent: boolean
  }
  submitErrors?: FormSubmitErrorsProps['errors']
  submitButton: SubmitButtonProps
}

const LoginForm: React.FC<LoginFormProps> = ({
  className,
  text,
  passwordRecovery = false,
  formik,
  fields,
  forgotPassword,
  submitErrors,
  submitButton,
}) => {
  console.log(forgotPassword)
  return (
    <SC.Form {...formik} className={className}>
      {passwordRecovery ? (
        <>
          <SC.TextPassword>{forgotPassword.title}</SC.TextPassword>
          <SC.Text>{forgotPassword.desc}</SC.Text>
          <FormikField {...fields.email} Component={SC.TextField} />
          <SC.ForgotPassword onClick={forgotPassword.onClick}>
            {submitButton.text}
          </SC.ForgotPassword>
          {submitErrors && <SC.SubmitErrors errors={submitErrors} />}
          <SC.Submit {...submitButton} text={forgotPassword.button} />
          {forgotPassword.mailSent && (
            <SC.SuccessMessage>{forgotPassword.successMessage}</SC.SuccessMessage>
          )}
        </>
      ) : (
        <>
          <SC.Text>{text}</SC.Text>
          <FormikField {...fields.email} Component={SC.TextField} />
          <FormikField {...fields.password} Component={SC.TextField} type="password" />
          <SC.ForgotPassword onClick={forgotPassword.onClick}>
            {forgotPassword.text}
          </SC.ForgotPassword>
          {submitErrors && <SC.SubmitErrors errors={submitErrors} />}
          <SC.Submit {...submitButton} />
        </>
      )}
    </SC.Form>
  )
}

export default LoginForm
