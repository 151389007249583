import React, { useEffect, useMemo, useState, useRef } from 'react'
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProps } from 'formik'

import ResetTemplate, { ResetTemplateProps } from '../../templates/Reset'
import { useHeaderProps } from '../../components/Header/useProps'
import Header from '../../components/Header'
import { actions, selectors } from '../../redux'
import { router, routesPath } from '../../router'
import i18n from '../../i18n'
import { ResetFormValues } from '../../components/forms/ResetForm'

const ResetPage: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const headerProps = useHeaderProps()

  const history = useHistory()

  const resetPasswordService = useSelector(selectors.auth.resetPassword)

  const $formikRef = useRef<FormikProps<ResetFormValues>>(null)

  const routerParams = useParams<{ locale?: string; token: string }>()

  const [languageSet, setLanguageSet] = useState(false)

  const [tokenState] = useState(routerParams.token)

  useEffect(() => {
    i18n.changeLanguage(routerParams.locale).then(() => {
      setLanguageSet(true)
    })
  }, [history, routerParams.locale])

  const templateProps: ResetTemplateProps = useMemo(
    () => ({
      layoutProps: {
        header: <Header {...headerProps} />,
      },
      title: t('resetPassword.title'),
      successMessage: t('resetPassword.success'),
      form: {
        formik: {
          innerRef: $formikRef,
          validationSchema: Yup.object().shape({
            email: Yup.string().required().email(),
            password: Yup.string().required(),
            passwordConfirmation: Yup.string()
              .required()
              .oneOf([Yup.ref('password'), null]),
          }),
          initialValues: {
            email: '',
            password: '',
            passwordConfirmation: '',
          },
          onSubmit: () => {
            dispatch(
              actions.auth.resetPasswordRequest({
                input: {
                  email: $formikRef.current!.values.email!,
                  password: $formikRef.current!.values.password!,
                  token: tokenState,
                },
              })
            )
          },
        },
        text: t('resetPassword.form.description'),
        fields: {
          email: {
            name: 'email',
            placeholder: t('forgotPassword.form.email.placeholder'),
            required: true,
          },
          password: {
            name: 'password',
            placeholder: t('resetPassword.form.password.placeholder'),
            required: true,
          },
          passwordConfirmation: {
            name: 'passwordConfirmation',
            placeholder: t('resetPassword.form.passwordConfirm.placeholder'),
            required: true,
          },
        },
        submitErrors: resetPasswordService.errors?.length
          ? [{ label: t('login.error') }]
          : undefined,
        submitButton: {
          isPending: resetPasswordService.pending,
          isSuccess: resetPasswordService.success,
          text: t('resetPassword.form.button'),
        },
        backButton: {
          text: t('resetPassword.getBack'),
          onClick: () => {
            history.push(router(routesPath.root))
          },
        },
      },
    }),
    [
      dispatch,
      headerProps,
      resetPasswordService.errors?.length,
      resetPasswordService.success,
      resetPasswordService.pending,
      t,
      history,
      tokenState,
    ]
  )

  return <>{languageSet && <ResetTemplate {...templateProps} />}</>
}

export default ResetPage
