import gql from 'graphql-tag'

import { AppGetEventQuery } from '../../../types/api'

export default {
  query: gql`
    query AppGetEvent($id: ID!) {
      event(id: $id) {
        id
        name
        type
        coatings
        colors
        sizes
        messageTypes
        presetMessages
        lines {
          id
          name
          bags {
            id
            name
            color
            sku
            packshot
          }
          items {
            id
            name
            color
            sku
            packshot
          }
          readyToWears {
            id
            name
            color
            sku
            packshot
          }
        }
        shoes {
          id
          name
          color
          sku
          sizes
          packshot
        }
      }
    }
  `,
  transformer: (response: AppGetEventQuery) => {
    return response.event
  },
}
