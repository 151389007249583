import styled from 'styled-components'

import { textStyles } from '../../theme'
import BagCustomizationForm from '../../components/forms/BagCustomizationForm'

export const Content = styled.div`
  padding: 4rem 1rem;
  flex-grow: 1;
`

export const Box = styled.div`
  max-width: 640px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  ${textStyles.titleH1};
  text-align: center;
`

export const Form = styled(BagCustomizationForm)`
  margin-top: 55px;
  width: 100%;
`

export const Pagination = styled.div`
  margin-top: 45px;
  ${textStyles.text};
`
