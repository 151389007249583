import React, { useCallback, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import StoreAndEventTemplate, { StoreAndEventTemplateProps } from '../../templates/StoreAndEvent'
import { useHeaderProps } from '../../components/Header/useProps'
import Header from '../../components/Header'
import { StoreAndEventFormValues } from '../../components/forms/StoreAndEventForm'
import { actions, selectors } from '../../redux'
import { EventType, SaleType } from '../../graphql/types/api'
import { Screen } from '../../types/global'
import { useSagaTakeEvery } from '../../hooks/useSaga'

const StoreAndEventPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // SELECTORS

  const stores = useSelector(selectors.app.stores)
  const zones = useSelector(selectors.app.zones)
  const storeAndEventStep = useSelector(selectors.app.storeAndEventStep)
  const getEvent = useSelector(selectors.app.getEvent)
  const getStoresByLocations = useSelector(selectors.app.getStoresByLocations)

  // REF

  const $formikRef = useRef<FormikProps<StoreAndEventFormValues>>(null)

  // STATE

  const [fieldsVisibility, setFieldsVisibility] = useState({
    zones: true,
    countries: !!storeAndEventStep.country,
    store: !!storeAndEventStep.storeId,
    event: !!storeAndEventStep.eventId,
    saleType: !!storeAndEventStep.saleType,
  })
  const [selectedStoreId, setSelectedStoreId] = useState(storeAndEventStep.storeId)
  const [selectedEventId, setSelectedEventId] = useState(storeAndEventStep.eventId)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(!!$formikRef.current?.values.saleType)

  // MEMO

  const events = useMemo(() => stores?.find((store) => store.id === selectedStoreId)?.events, [
    selectedStoreId,
    stores,
  ])

  const selectedEvent = useMemo(() => events?.find((_event) => _event.id === selectedEventId), [
    events,
    selectedEventId,
  ])

  const gotToNextStep = useCallback(() => {
    switch (selectedEvent?.type) {
      case EventType.Bags:
      case EventType.Rtw:
      case EventType.Items:
        dispatch(actions.app.setScreen({ screen: Screen.BagCustomisation }))
        break
      case EventType.Shoes:
        dispatch(actions.app.setScreen({ screen: Screen.ShoesCustomisation }))
        break
    }
  }, [dispatch, selectedEvent?.type])

  // PROPS

  const headerProps = useHeaderProps()

  const templateProps: StoreAndEventTemplateProps = useMemo(() => {
    return {
      layoutProps: {
        header: <Header {...headerProps} />,
      },
      title: t('storeAndEvent.title'),
      form: {
        formik: {
          innerRef: $formikRef,
          validationSchema: Yup.object().shape({
            zone: Yup.string().required(),
            country: Yup.string().required(),
            store: Yup.string().required(),
            event: Yup.string().required(),
            saleType: Yup.string().required(),
          }),
          initialValues: {
            zone: storeAndEventStep.zone ?? '',
            country: storeAndEventStep.country ?? '',
            store: storeAndEventStep.storeId ?? '',
            event: storeAndEventStep.eventId ?? '',
            saleType: storeAndEventStep.saleType ?? '',
          },
          onSubmit: (values) => {
            dispatch(
              actions.app.setStoreAndEventStep({
                zone: values.zone,
                country: values.country,
                storeId: values.store,
                eventId: values.event,
                saleType: values.saleType as SaleType,
              })
            )
            if (getEvent.data?.id !== values.event) {
              dispatch(actions.app.getEventRequest({ id: values.event }))
            } else {
              gotToNextStep()
            }
          },
          onContextUpdate: () => {
            //console.log($formikRef)
            setSelectedStoreId($formikRef.current?.values.store ?? null)
            setSelectedEventId($formikRef.current?.values.event ?? null)
            setIsSubmitEnabled(!!$formikRef.current?.values.saleType)
          },
        },
        fields: {
          zone: fieldsVisibility.zones
            ? {
                name: 'zone',
                placeholder: t('storeAndEvent.form.zone.placeholder'),
                options:
                  zones?.map((zone: any) => ({
                    label: zone.name,
                    value: zone.id,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      countries: true,
                      store: false,
                      event: false,
                      saleType: false,
                    }))
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      country: '',
                      store: '',
                      event: '',
                      saleType: '',
                    })
                  }, 10)
                },
              }
            : undefined,
          country: fieldsVisibility.countries
            ? {
                name: 'country',
                placeholder: t('storeAndEvent.form.country.placeholder'),
                options:
                  zones
                    ?.find((el) => el?.id === $formikRef.current?.values.zone)
                    ?.countries.map((country: any) => {
                      return {
                        label: country.name,
                        value: country.id,
                      }
                    }) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      store: true,
                      event: false,
                      saleType: false,
                    }))
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      store: '',
                      event: '',
                      saleType: '',
                    })

                    $formikRef.current &&
                      dispatch(
                        actions.app.getStoresByLocationsRequest({
                          input: {
                            zone: $formikRef?.current?.values.zone,
                            country: $formikRef?.current?.values.country,
                          },
                        })
                      )
                  }, 10)
                },
              }
            : undefined,
          store: fieldsVisibility.store
            ? {
                name: 'store',
                placeholder: t('storeAndEvent.form.store.placeholder'),
                options:
                  getStoresByLocations?.data?.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.id,
                    }
                  }) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      event: true,
                      saleType: false,
                    }))
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      event: '',
                      saleType: '',
                    })
                  }, 10)
                },
              }
            : undefined,
          event: fieldsVisibility.event
            ? {
                name: 'event',
                placeholder: t('storeAndEvent.form.event.placeholder'),
                options:
                  events?.map((event) => ({
                    label: event.name,
                    value: event.id,
                  })) ?? [],
                onChange: () => {
                  setTimeout(() => {
                    $formikRef.current?.setValues({
                      ...$formikRef.current.values,
                      saleType: '',
                    })
                    setFieldsVisibility((prevState) => ({
                      ...prevState,
                      saleType: true,
                    }))
                  }, 10)
                },
              }
            : undefined,
          saleType: fieldsVisibility.saleType
            ? {
                name: 'saleType',
                placeholder: t('storeAndEvent.form.saleType.placeholder'),
                options: [
                  {
                    label: t('enum.saleType.STANDARD'),
                    value: SaleType.Standard,
                  },
                  {
                    label: t('enum.saleType.DISTANT'),
                    value: SaleType.Distant,
                  },
                ],
              }
            : undefined,
        },
        back: {
          label: t('storeAndEvent.back'),
          onClick: () => {
            dispatch(actions.app.setScreen({ screen: Screen.home }))
          },
        },
        submitButton: {
          text: t('storeAndEvent.submitButton'),
          disabled: !isSubmitEnabled || getEvent.pending,
        },
      },
      pagination: {
        current: 2,
        total: 4,
      },
    }
  }, [
    headerProps,
    t,
    storeAndEventStep.zone,
    storeAndEventStep.country,
    storeAndEventStep.storeId,
    storeAndEventStep.eventId,
    storeAndEventStep.saleType,
    fieldsVisibility.zones,
    fieldsVisibility.countries,
    fieldsVisibility.store,
    fieldsVisibility.event,
    fieldsVisibility.saleType,
    zones,
    getStoresByLocations?.data,
    events,
    isSubmitEnabled,
    getEvent.pending,
    getEvent.data?.id,
    dispatch,
    gotToNextStep,
  ])

  // SAGA

  useSagaTakeEvery(
    actions.app.getEventSuccess,
    useCallback(() => {
      gotToNextStep()
    }, [gotToNextStep])
  )
  // TODO: redirect to home if no data in store

  // RETURN

  return <StoreAndEventTemplate {...templateProps} />
}

export default StoreAndEventPage
