import React, { FC } from 'react'

import { Icons } from '../../Icon/types'

import * as SC from './styled'

export type SelectProps = {
  className?: string
  id?: string
  label?: string
  name: string
  value?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  error?: string
  showError?: boolean
  hasBorder?: boolean
  helperText?: string
  helperTextSide?: 'start' | 'end'
  options: { label: string; value: string }[]
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
}

const Select: FC<SelectProps> = ({
  className,
  id,
  name,
  label,
  placeholder,
  value,
  required = false,
  disabled = false,
  error,
  showError = true,
  hasBorder = true,
  helperText,
  helperTextSide = 'end',
  onChange,
  options,
}) => {
  return (
    <SC.Wrapper className={className}>
      {label && (
        <SC.Label required={required} error={!!error}>
          {label}
        </SC.Label>
      )}
      <SC.SelectContainer>
        <SC.Select
          id={id}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
          $error={!!error}
          $hasBorder={hasBorder}
        >
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {options
            ?.sort((a, b) => a.label.localeCompare(b.label))
            .map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </SC.Select>
        <SC.SelectArrow icon={Icons.chevronDown} />
      </SC.SelectContainer>
    </SC.Wrapper>
  )
}

export default Select
