import React, { FC } from 'react'

import { EventType } from '../../graphql/types/api'

import * as SC from './styled'

export type ProductDetailProps = {
  className?: string
  image: {
    src: string
  }
  type: string
  title: string
  details: string[]
  customization: any
}

const ProductDetail: FC<ProductDetailProps> = (props) => {
  const { className, image, title, details, customization, type } = props

  return (
    <SC.Container className={className}>
      <SC.ImageContainer>
        <SC.Image src={image.src} />
      </SC.ImageContainer>
      <SC.TextContainer>
        <SC.Name>{title}</SC.Name>
        {details?.map((item, index) => (
          <SC.DataBlock key={index}>
            <SC.DataLabel>{item}</SC.DataLabel>
          </SC.DataBlock>
        ))}
        <SC.CustomizationBlock>
          <SC.CustomizationLabel>Customization</SC.CustomizationLabel>
          {type === EventType.Shoes ? (
            <SC.CustomizationShoes>
              <SC.CustomizationSide>
                <SC.CustomizationLabel>Left</SC.CustomizationLabel>
                <SC.Customization>{customization.left.shank}</SC.Customization>
                <SC.Customization>{customization.left.upper1}</SC.Customization>
                <SC.Customization>{customization.left.upper2}</SC.Customization>
              </SC.CustomizationSide>
              <SC.CustomizationSide>
                <SC.CustomizationLabel>Right</SC.CustomizationLabel>
                <SC.Customization>{customization.right.shank}</SC.Customization>
                <SC.Customization>{customization.right.upper1}</SC.Customization>
                <SC.Customization>{customization.right.upper2}</SC.Customization>
              </SC.CustomizationSide>
            </SC.CustomizationShoes>
          ) : (
            <SC.Customization>{customization.message}</SC.Customization>
          )}
        </SC.CustomizationBlock>
      </SC.TextContainer>
    </SC.Container>
  )
}

export default ProductDetail
