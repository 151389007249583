import gql from 'graphql-tag'

import { ResetPasswordInput, MutationResetPasswordArgs } from '../../../types/api'

export default {
  mutation: gql`
    mutation resetPassword($input: ResetPasswordInput!) {
      resetPassword(input: $input)
    }
  `,
  transformer: (response: MutationResetPasswordArgs) => {
    return response
  },
}
