import styled from 'styled-components'

import FormSubmitErrors from '../../form/FormSubmitErrors'
import SubmitButton from '../../form/SubmitButton'
import FormikForm from '../../form/FormikForm'
import { textStyles } from '../../../theme'
import Select from '../../form/Select'
import TextField from '../../form/TextField'

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SelectField = styled(Select)`
  &:not(:first-child) {
    margin-top: 25px;
  }
`
export const TextFieldField = styled(TextField)`
  &:not(:first-child) {
    margin-top: 25px;
  }
`

export const Text = styled.p`
  ${textStyles.text};
  text-align: center;
`

export const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin-top: 50px;
  }
`

export const GroupLabel = styled.p`
  ${textStyles.titleH3};
`

export const SubmitErrors = styled(FormSubmitErrors)`
  margin-top: 25px;
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
`

export const Back = styled.div`
  ${textStyles.text};
  padding: 10px 30px;
  cursor: pointer;
  margin-right: 25px;
  text-transform: uppercase;
`

export const Submit = styled(SubmitButton)`
  width: 100%;
  max-width: 250px;
`
